import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, useMemo, useState } from 'react';
import { Picky } from 'react-picky';
import _isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import _isPlainObject from 'lodash/isPlainObject';
import ReactSelect, { components } from 'react-select';
import { selectInputStyles, selectInputStylesLightMode } from 'rapidfab/constants/styles';
import Feature from '../Feature';

const { ValueContainer, Placeholder, Option } = components;

const RenderCustomIcon = ({
  customOptionIcon,
  availableData,
  customOptionIconReadOnly,
  value,
  isSelected,
  customIsSelectedColor,
}) => {
  if (!customOptionIcon || value === '--select-all') return null;

  const customAvailableColor = customIsSelectedColor || '#1CA8DD';
  const customSelectedColor = customIsSelectedColor || '#1CA8DD';

  const isAvailable = availableData.includes(value);
  const iconColor = customOptionIconReadOnly
    ? (isAvailable ? customAvailableColor : 'unset')
    : (isSelected ? customSelectedColor : 'unset');

  const shouldRenderIcon = customOptionIconReadOnly
    ? isAvailable
    : true;

  return shouldRenderIcon ? (
    <FontAwesomeIcon
      icon={customOptionIcon}
      className="spacer-left"
      color={iconColor}
    />
  ) : null;
};

RenderCustomIcon.defaultProps = {
  customOptionIcon: null,
  availableData: [],
  customOptionIconReadOnly: false,
  value: '',
  isSelected: false,
  customIsSelectedColor: '',
};

RenderCustomIcon.propTypes = {
  customOptionIcon: PropTypes.shape({}),
  availableData: PropTypes.arrayOf(PropTypes.string),
  customOptionIconReadOnly: PropTypes.bool,
  value: PropTypes.string,
  isSelected: PropTypes.bool,
  customIsSelectedColor: PropTypes.string,
};

/**
 * Custom container for React Select input
 * This hides the placeholder only when text is being entered
 * (usually hides it whenever something is selected)
 * Also changes the placeholder to be gray when the menu is open
 * @param {import('react-select').ValueContainerProps} props
 */
const CustomContainer = ({ children, ...props }) => {
  const menuOpen = props.selectProps.menuIsOpen;
  const hasInput = props.selectProps.inputValue.length;
  return (
    <ValueContainer {...props}>
      {!hasInput && (
        <Placeholder {...props}>
          <span style={{ color: menuOpen ? 'gray' : 'white' }}>{props.selectProps.placeholder}</span>
        </Placeholder>
      )}
      {React.Children.map(children, child =>
        (child && child.key !== 'placeholder' ? child : null),
      )}
    </ValueContainer>
  );
};

/**
 * @param {import('react-select').OptionProps} props
 */
const CustomOption = props => {
  const { data, isSelected, selectProps } = props;
  const { customOptionIcon, availableData, customOptionIconReadOnly } = selectProps;

  return (
    <Option {...props}>
      {/* eslint-disable-next-line react/prop-types */}
      <input type="checkbox" checked={props.isSelected} />{' '}
      {/* eslint-disable-next-line react/prop-types */}
      {props.children}
      <RenderCustomIcon
        customOptionIconReadOnly={customOptionIconReadOnly}
        availableData={availableData}
        customOptionIcon={customOptionIcon}
        value={data.value}
        isSelected={isSelected}
        customIsSelectedColor="#FFFB00"
      />
    </Option>
  );
};

CustomOption.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  selectProps: PropTypes.shape({
    customOptionIcon: PropTypes.shape({}),
    availableData: PropTypes.arrayOf(PropTypes.string),
    customOptionIconReadOnly: PropTypes.bool,
  }).isRequired,
};

/**
 * @param {object[] | object | null} data
 *
 * Converts single object to array and null to empty array
 */
const normalizeSelectedData = (data, labelKey, valueKey) => {
  if (_isPlainObject(data)) {
    // eslint-disable-next-line no-param-reassign
    data = [data];
  }
  if (data === null) {
    return [];
  }
  if (labelKey && valueKey) {
    return data.map(option => ({
      value: option[valueKey],
      label: option[labelKey],
    }));
  }
  return data;
};

/**
 * @component
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.className
 * @param {object[]} props.data
 * @param {string} props.labelKey
 * @param {string} props.valueKey
 * @param {object[] | object} props.selectedData
 * @param {() => void} props.handleOnClose
 * @param {boolean} props.showBadge
 * @param {boolean} props.keepOpen
 * @param {boolean} props.includeFilter
 * @param {boolean} props.disabled
 * @param {boolean} props.selectAllOption
 * @param {string} props.dynamicPlaceholderText
 * @param {boolean} props.lightMode
 */
const SelectMultiple = ({
  data,
  title,
  selectedData = [],
  className,
  keepOpen = true,
  includeFilter = true,
  disabled = false,
  selectAllOption = false,
  showBadge = true,
  menuClassNames,
  customOptionIcon,
  availableData = [],
  customOptionIconReadOnly = false,
  ...props
}) => {
  const [selected, setSelected] = useState(normalizeSelectedData(selectedData, props.labelKey, props.valueKey));
  const options = useMemo(() => {
    const mappedOptions = data.map(option => ({
      value: option[props.valueKey],
      label: option[props.labelKey],
      isDisabled: option.disabled,
    }));

    if (selectAllOption) {
      // Determine if all enabled items are selected
      const enabledOptions = mappedOptions.filter(o => !o.isDisabled);
      const allEnabledSelected = selected.length === enabledOptions.length;
      return [
        { value: '--select-all', label: allEnabledSelected ? 'Deselect All' : 'Select All' },
        ...mappedOptions,
      ];
    }
    return mappedOptions;
  },
  [data, props.labelKey, props.valueKey, selected.length]);

  const handleOnChange = data => {
    if (selectAllOption && data.some(option => option.value === '--select-all')) {
      // Filter out disabled items and select only enabled ones
      const enabledOptions = options.filter(o => o.value !== '--select-all' && !o.isDisabled);
      const allEnabledSelected = selected.length === enabledOptions.length;
      setSelected(allEnabledSelected ? [] : enabledOptions);
      return;
    }
    setSelected(normalizeSelectedData(data));
  };

  // Transform selected options back to their input labels and keys
  const handleOnClose = () => {
    const transformed = selected.map(option => ({
      [props.labelKey]: option.label,
      [props.valueKey]: option.value,
    }));
    props.handleOnClose(transformed);
    return true;
  };

  const getDynamicPlaceholder = () => {
    const { dynamicPlaceholderText } = props;
    const count = selected.length;
    if (count === 0) return dynamicPlaceholderText || 'None selected';
    return `${count} selected`;
  };

  const placeholder = props.dynamicPlaceholderText ? getDynamicPlaceholder() : title;

  return (
    <span className={className}>
      {
        showBadge &&
        <sup className="badge-pull-right"><span className={`pickyNumber ${!selected.length ? 'pickyNumber__hidden' : ''}`}>{selected.length}</span></sup>
      }
      <ReactSelect
        isMulti
        classNames={{
          menu: state => (state.selectProps.menuIsOpen ? menuClassNames : ''),
        }}
        value={selected}
        options={options}
        styles={props.lightMode ? selectInputStylesLightMode : selectInputStyles}
        placeholder={placeholder}
        hideSelectedOptions={false}
        isSearchable={includeFilter}
        closeMenuOnSelect={!keepOpen}
        isClearable={false}
        customOptionIcon={customOptionIcon}
        availableData={availableData}
        customOptionIconReadOnly={customOptionIconReadOnly}
        isDisabled={disabled}
        onChange={handleOnChange}
        onMenuClose={handleOnClose}
        className="wrap-text"
        // Multivalue being empty hides the selected items appearing in the text input
        components={{ MultiValue: () => <div />, ValueContainer: CustomContainer, Option: CustomOption }}
      />
    </span>
  );
};

SelectMultiple.defaultProps = {
  className: null,
  multiple: true,
  selectedData: [],
  showBadge: true,
  keepOpen: true,
  includeFilter: true,
  disabled: false,
  selectAllOption: false,
  dynamicPlaceholderText: '',
  menuClassNames: '',
  lightMode: false,
  availableData: [],
  customOptionIcon: null,
  customOptionIconReadOnly: false,
};

SelectMultiple.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  labelKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  selectedData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  handleOnClose: PropTypes.func.isRequired,
  showBadge: PropTypes.bool,
  keepOpen: PropTypes.bool,
  includeFilter: PropTypes.bool,
  disabled: PropTypes.bool,
  selectAllOption: PropTypes.bool,
  dynamicPlaceholderText: PropTypes.string,
  menuClassNames: PropTypes.string,
  lightMode: PropTypes.bool,
  availableData: PropTypes.arrayOf(PropTypes.string),
  customOptionIcon: PropTypes.shape({}),
  customOptionIconReadOnly: PropTypes.bool,
};

CustomContainer.propTypes = {
  selectProps: PropTypes.shape({
    placeholder: PropTypes.string,
    menuIsOpen: PropTypes.bool,
    inputValue: PropTypes.string,
  }).isRequired,
  children: PropTypes.element.isRequired,
};

class OldSelectMultiple extends Component {
  static normalizeSelectedData(data) {
    let selectedData = data;
    if (_isPlainObject(data)) {
      selectedData = [selectedData];
    }
    if (selectedData === null) {
      selectedData = [];
    }
    return selectedData;
  }

  constructor(props) {
    super(props);

    const selectedData = OldSelectMultiple.normalizeSelectedData(this.props.selectedData);
    this.state = {
      selectedData,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.renderOption = this.renderOption.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { selectedData } = this.props;
    const normalizedSelectedData = OldSelectMultiple.normalizeSelectedData(selectedData);
    if (
      !_isEqual(
        normalizedSelectedData,
        OldSelectMultiple.normalizeSelectedData(prevProps.selectedData),
      )
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedData: normalizedSelectedData });
    }
  }

  handleOnChange(data) {
    this.setState({ selectedData: OldSelectMultiple.normalizeSelectedData(data) });
  }

  handleOnClose() {
    const { multiple } = this.props;
    let { selectedData } = this.state;

    selectedData = OldSelectMultiple.normalizeSelectedData(selectedData);

    if (!multiple) {
      if (selectedData && selectedData.length > 0) {
        this.props.handleOnClose(selectedData[0]);
      } else {
        this.props.handleOnClose(null);
      }
      return false;
    }
    this.props.handleOnClose(selectedData);
    return true;
  }

  getDynamicPlaceholder() {
    const { dynamicPlaceholderText } = this.props;
    const count = this.state.selectedData.length;
    if (count === 0) return dynamicPlaceholderText || 'None selected';
    return `${count} selected`;
  }

  // Default react-picky JSX with the optional disabled props passed
  renderOption({ index, item, selectValue, multiple, isSelected }) {
    const isDisabled = item.disabled;
    const { labelKey, valueKey, customOptionIcon, availableData, customOptionIconReadOnly } = this.props;

    return (
      <li
        key={index}
        className={`picky__item ${
          isSelected ? 'picky__item--selected' : ''
        } ${isDisabled ? 'picky__item--disabled' : ''}`}
        onClick={() => !isDisabled && selectValue(item)}
        role="option"
        aria-selected={isSelected}
      >
        {multiple && (
          <span className="picky__checkbox">
            <input
              type="checkbox"
              readOnly
              tabIndex="-1"
              checked={isSelected}
              disabled={isDisabled}
            />
          </span>
        )}
        <span className="picky__label">{item[labelKey]}</span>
        <RenderCustomIcon
          customOptionIconReadOnly={customOptionIconReadOnly}
          availableData={availableData}
          customOptionIcon={customOptionIcon}
          value={item[valueKey]}
          isSelected={isSelected}
        />
      </li>
    );
  }

  render() {
    const {
      title, data, labelKey, valueKey,
      className, multiple, showBadge, keepOpen,
      includeFilter,
      disabled,
      selectAllOption,
      dynamicPlaceholderText,
    } = this.props;
    const placeholder = dynamicPlaceholderText ? this.getDynamicPlaceholder() : title;
    const { selectedData } = this.state;
    const selectedDataCount = selectedData && selectedData.length;

    return (
      <span className={className}>
        {
          showBadge &&
          <sup className="badge-pull-right"><span className={`pickyNumber ${!selectedDataCount ? 'pickyNumber__hidden' : ''}`}>{selectedDataCount}</span></sup>
        }
        <Picky
          placeholder={placeholder}
          manySelectedPlaceholder={title}
          allSelectedPlaceholder={title}
          options={data}
          labelKey={labelKey}
          valueKey={valueKey}
          includeFilter={includeFilter}
          value={selectedData}
          keepOpen={keepOpen}
          disabled={disabled}
          multiple={multiple}
          onChange={values => this.handleOnChange(values)}
          onClose={values => this.handleOnClose(values)}
          dropdownHeight={600}
          numberDisplayed={0}
          className="wrap-text"
          render={this.renderOption}
          renderSelectAll={selectAllOption ? ({
            filtered,
            tabIndex,
          }) => {
            if (multiple && !filtered) {
              const { data } = this.props;
              const { selectedData } = this.state;
              const enabledItems = data.filter(item => !item.disabled);
              const allEnabledSelected = selectedData && selectedData.length === enabledItems.length;

              return (
                <div
                  tabIndex={tabIndex}
                  role="option"
                  aria-selected={allEnabledSelected}
                  className={allEnabledSelected ? 'option selected' : 'option'}
                  onClick={() => {
                    this.handleOnChange(allEnabledSelected ? [] : enabledItems);
                  }}
                  onKeyPress={() => {
                    this.handleOnChange(allEnabledSelected ? [] : enabledItems);
                  }}
                >
                  <h5 style={{ color: '#000' }}>Select All</h5>
                </div>
              );
            }
            return null;
          } : null}
        />
      </span>
    );
  }
}
OldSelectMultiple.defaultProps = {
  className: null,
  multiple: true,
  selectedData: [],
  showBadge: true,
  keepOpen: true,
  includeFilter: true,
  disabled: false,
  selectAllOption: false,
  dynamicPlaceholderText: '',
  customOptionIcon: null,
  availableData: [],
  customOptionIconReadOnly: false,
};
OldSelectMultiple.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  labelKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  selectedData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  handleOnClose: PropTypes.func.isRequired,
  showBadge: PropTypes.bool,
  keepOpen: PropTypes.bool,
  includeFilter: PropTypes.bool,
  disabled: PropTypes.bool,
  selectAllOption: PropTypes.bool,
  dynamicPlaceholderText: PropTypes.string,
  customOptionIcon: PropTypes.shape({}),
  availableData: PropTypes.arrayOf(PropTypes.string),
  customOptionIconReadOnly: PropTypes.bool,
};

const Export = props => (
  <>
    <Feature featureName="experiment-alpha">
      <SelectMultiple {...props} />
    </Feature>
    <Feature featureName="experiment-alpha" isInverted>
      <OldSelectMultiple {...props} />
    </Feature>
  </>
);

export default Export;
