import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import React from 'react';
import { Card, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ResourceListItem = ({
  resourceType,
  route,
  resourceCount,
  isLoading,
  resourceState,
  handleSetLocationFilters,
}) => {
  const { uri } = resourceState;
  return (
    <ListGroupItem className="resource-list-item-card-item">
      <Row>
        <Col xs={4}>
          <span>{resourceType}</span>
        </Col>
        <Col xs={4}>
          <span>{isLoading ? <Loading inline /> : resourceCount}</span>
        </Col>
        <Col xs={4}>
          {
            isLoading ? (
              <Loading inline />
            ) : (
              <Link
                onClick={() => handleSetLocationFilters(uri, '')}
                rel="noopener noreferrer"
                to={{
                  pathname: getRouteURI(route,
                    null,
                    null, true),
                }}
              >
                <FontAwesomeIcon icon={faExternalLink} />
              </Link>
            )
          }
        </Col>
      </Row>
    </ListGroupItem>
  );
};

ResourceListItem.defaultProps = {
  resourceCount: null,
  isLoading: false,
};

ResourceListItem.propTypes = {
  resourceType: PropTypes.string.isRequired,
  resourceCount: PropTypes.number,
  isLoading: PropTypes.bool,
  route: PropTypes.string.isRequired,
  resourceState: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    subLocationUUID: PropTypes.string.isRequired,
    subLocationUri: PropTypes.string.isRequired,
  }).isRequired,
  handleSetLocationFilters: PropTypes.func.isRequired,
};

const ResourceCard = ({
  title,
  resources,
  locationUri,
  renderResourceCount,
  isLoading,
  handleSetLocationFilters,
}) => (
  <Card bg="dark" className="mb15 resource-list-item-card location-resources">
    <ListGroup fill>
      <div key="header" className="resource-list-item-card-header">
        <Row>
          <Col xs={4}>
            <b>{title}</b>
          </Col>
          <Col xs={4}>
            <b>Amount</b>
          </Col>
          <Col xs={4}>
            <b>Link</b>
          </Col>
        </Row>
      </div>
      {resources.map(resource => (
        <ResourceListItem
          key={resource.resourceType}
          resourceType={resource.resourceType}
          route={resource.route}
          resourceState={{ uri: locationUri }}
          resourceCount={renderResourceCount(resource.apiResource, locationUri)}
          isLoading={isLoading}
          handleSetLocationFilters={handleSetLocationFilters}
        />
      ))}
    </ListGroup>
  </Card>
);

ResourceCard.propTypes = {
  title: PropTypes.string.isRequired,
  resources: PropTypes.arrayOf(PropTypes.shape({
    resourceType: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    apiResource: PropTypes.string.isRequired,
  })).isRequired,
  locationUri: PropTypes.string.isRequired,
  renderResourceCount: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleSetLocationFilters: PropTypes.func.isRequired,
};

const LocationResources = ({
  locationUri,
  renderResourceCount,
  materialResourcesFetching,
  handleSetLocationFilters,
}) => {
  // Define the resource sections and their respective resources
  const resourceSections = [
    {
      title: 'Assets',
      resources: [
        {
          resourceType: 'Printers',
          route: ROUTES.PRINTERS,
          apiResource: API_RESOURCES.PRINTER,
        },
        {
          resourceType: 'Post Processors',
          route: ROUTES.POST_PROCESSORS,
          apiResource: API_RESOURCES.POST_PROCESSOR,
        },
      ],
    },
    {
      title: 'Inventory',
      resources: [
        {
          resourceType: 'Lots',
          route: ROUTES.MATERIAL_LOTS,
          apiResource: API_RESOURCES.MATERIAL_LOT,
        },
        {
          resourceType: 'Batches',
          route: ROUTES.MATERIAL_BATCHES,
          apiResource: API_RESOURCES.MATERIAL_BATCH,
        },
        {
          resourceType: 'Permanent Containers',
          route: ROUTES.PERMANENT_CONTAINERS,
          apiResource: API_RESOURCES.MATERIAL_CONTAINER,
        },
        {
          resourceType: 'Tools',
          route: ROUTES.TOOLING_STOCKS,
          apiResource: API_RESOURCES.TOOLING_STOCK,
        },
      ],
    },
  ];

  return (
    <Row>
      {resourceSections.map(section => (
        <Col xs={6} key={section.title}>
          <ResourceCard
            title={section.title}
            resources={section.resources}
            locationUri={locationUri}
            renderResourceCount={renderResourceCount}
            isLoading={materialResourcesFetching}
            handleSetLocationFilters={handleSetLocationFilters}
          />
        </Col>
      ))}
    </Row>
  );
};

LocationResources.propTypes = {
  locationUri: PropTypes.string.isRequired,
  renderResourceCount: PropTypes.func.isRequired,
  materialResourcesFetching: PropTypes.bool.isRequired,
  handleSetLocationFilters: PropTypes.func.isRequired,
};

export default LocationResources;
