import { useState, useCallback } from 'react';

export default function usePagination(initialPageLimit = 15, initialTotalItems = 0) {
  const [paginationState, setPaginationState] = useState({
    pageLimit: initialPageLimit,
    offset: 0,
    activePage: 0,
    totalItems: initialTotalItems,
  });

  const resetOffset = useCallback(() => {
    setPaginationState(prevState => ({
      ...prevState,
      offset: 0,
    }));
  }, []);

  const setTotalItems = useCallback(total => {
    setPaginationState(prevState => ({
      ...prevState,
      totalItems: total,
      activePage: prevState.activePage >= Math.ceil(total / prevState.pageLimit) && prevState.activePage !== 0 ?
        Math.ceil(total / prevState.pageLimit) - 1 :
        prevState.activePage,
    }));
  }, []);

  const setPage = useCallback(page => {
    setPaginationState(prevState => ({
      ...prevState,
      activePage: page,
      offset: page * prevState.pageLimit,
    }));
  }, []);

  const nextPage = useCallback(() => {
    setPaginationState(prevState => {
      const next = prevState.activePage + 1;
      const totalPages = Math.ceil(prevState.totalItems / prevState.pageLimit);
      return next < totalPages ? {
        ...prevState,
        activePage: next,
        offset: next * prevState.pageLimit,
      } : prevState;
    });
  }, []);

  const goToPage = useCallback(offset => {
    setPaginationState(prevState => {
      const newActivePage = Math.floor(offset / prevState.pageLimit);
      return {
        ...prevState,
        offset,
        activePage: newActivePage,
      };
    });
  }, []);

  const onLimitChange = useCallback(limit => {
    setPaginationState(prevState => ({
      ...prevState,
      pageLimit: limit,
      activePage: 0,
      offset: 0,
    }));
  }, []);

  const previousPage = useCallback(() => {
    setPaginationState(prevState => {
      const previous = prevState.activePage - 1;
      return previous >= 0 ? {
        ...prevState,
        activePage: previous,
        offset: previous * prevState.pageLimit,
      } : prevState;
    });
  }, []);

  const totalPaginatedPages = Math.ceil(paginationState.totalItems / paginationState.pageLimit);

  return {
    paginationState,
    setTotalItems,
    setPage,
    nextPage,
    prevPage: previousPage,
    totalPaginatedPages,
    goToPage,
    onLimitChange,
    resetOffset,
  };
}
