import PropTypes from 'prop-types';
import EditLineItemQuoteModal from 'rapidfab/components/order-quote/EditLineItemQuoteModal';
import React, { createContext, useCallback, useContext, useState } from 'react';

const QuoteProcessStepsModalContext = createContext(null);

export const QuoteProcessStepsModalContextProvider = ({
  orderQuoteFFEnabled,
  workstepQuoteDetailsFFEnabled,
  children,
}) => {
  const [isOrderQuoteModalActive, setIsOrderQuoteModalActive] = useState(false);
  // Selected line item. If null, use first found line item
  const [lineItemUri, setLineItemUri] = useState(null);

  const showQuoteModal = useCallback(args => {
    setLineItemUri(args.lineItemUri ?? null);
    setIsOrderQuoteModalActive(true);
  }, []);

  const hideQuoteModal = useCallback(() => {
    setIsOrderQuoteModalActive(false);
  }, []);

  if (!orderQuoteFFEnabled && !workstepQuoteDetailsFFEnabled) {
    return children;
  }

  return (
    <QuoteProcessStepsModalContext.Provider
      value={{
        showQuoteModal,
        hideQuoteModal,
      }}
    >
      {children}
      {isOrderQuoteModalActive && (
        <EditLineItemQuoteModal
          initialLineItemUri={lineItemUri}
          show={isOrderQuoteModalActive}
          close={hideQuoteModal}
        />
      )}
    </QuoteProcessStepsModalContext.Provider>
  );
};

QuoteProcessStepsModalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  orderQuoteFFEnabled: PropTypes.bool.isRequired,
  workstepQuoteDetailsFFEnabled: PropTypes.bool.isRequired,
};

export const useQuoteModal = () => useContext(QuoteProcessStepsModalContext);
