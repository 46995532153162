import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { USER_ROLES_BY_VALUES } from 'rapidfab/constants';
import React from 'react';
import { Badge } from 'react-bootstrap';

const UserRolesBadges = ({ roles }) => {
  if (_isEmpty(roles)) return null;

  const badgeColorByRole = {
    manager: 'outlined-badge-success',
    'global-user': 'outlined-badge-warning',
    restricted: 'outlined-badge-danger',
    'standard-user': 'outlined-badge-primary',
  };

  return roles.map(userRole => (
    <Badge
      key={userRole.id}
      className={`badge-sm outlined-badge ${badgeColorByRole[userRole.role]} users-list-roles-badge`}

    >
      <span>{USER_ROLES_BY_VALUES[userRole.role]}</span>
    </Badge>
  ));
};

UserRolesBadges.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.shape({})),
};

UserRolesBadges.defaultProps = {
  roles: [],
};

export default UserRolesBadges;
