import { faQrcode, faUserGear, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import LocationUserRoleBadgeIcon from 'rapidfab/components/admin/LocationUserBadgeIcon';
import UserRolesBadges from 'rapidfab/components/admin/UserRolesBadges';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import SelectMultiple from 'rapidfab/components/forms/SelectMultiple';
import Loading from 'rapidfab/components/Loading';
import QrLogonCodeModal, { QrLogonCodeDisclaimerConfirmationModal } from 'rapidfab/components/qr/QrLogonCodeModal';
import Tooltip from 'rapidfab/components/Tooltip';
import { USER_ROLES } from 'rapidfab/constants';
import React from 'react';
import { Button, Col, Form, FormControl, FormGroup, FormLabel, InputGroup, Row } from 'react-bootstrap';

const styles = {
  spacingVertical: { display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '30px' },
  spacingBottom: { marginBottom: '0.5rem' },
  spacingTop: { marginTop: '1.5rem' },
};

const ManageUser = ({
  userProps,
  roleProps,
  locationProps,
  qrProps,
  tooltipProps,
  financialStateProps,
}) => {
  const {
    locations,
    disableConditions,
    onLocationsChange,
    dataLocations,
    locationsByUri,
    currentUserLocations,
    locationManagerLocationRoles,
  } = locationProps;

  const {
    isContactlessLoginEnabled,
    showQrLogonCodeWarningDisclaimerModal,
    onUserConfirmGetContactlessLogonQRCode,
    showQrLogonCodeModal,
    setShowQrLogonCodeModal,
    qrCodeBase64Data,
    isLoadingQRCodeData,
    setShowQrLogonCodeWarningDisclaimerModal,
  } = qrProps;

  const {
    user,
    rolesToShow,
    roleFetchState,
    updatedUserRoles,
    isCurrentUserManager,
    selectedUserName,
    currentUserName,
    handleToggleRole,
    isRoleSaving,
    userRoles,
    selectedUserRole,
    isLocationManager,
    disabledRolesForLocationManager,
    currentUserRole,
    handleToggleLocationManagerUI,
    currentLocationManagerLocations,
    selectedUserHighestRole,
    isUserViewedCurrentUser,
  } = userProps;

  const {
    confirmGlobal,
    removingGlobal,
    setRemovingGlobal,
    handleConfirmGlobalUserRole,
    handleRemoveGlobalUserRole,
    handleDenySwitchingGlobalUserRole,
  } = roleProps;

  const {
    renderTooltipConditions,
    conditionsRendering,
  } = tooltipProps;

  const {
    hideFinancialState,
    toggleHideFinancials,
  } = financialStateProps;

  const locationManagerLocationUris = locationManagerLocationRoles.map(location => location.uri);

  const isSelectedUserManager = selectedUserHighestRole?.role === USER_ROLES.MANAGER;

  const renderLocationManagerIcon = (
    <FontAwesomeIcon
      icon={faUserGear}
      color={!_isEmpty(locationManagerLocationUris) && '#1ac98e'}
      className="location-manager-icon"
      onClick={handleToggleLocationManagerUI}
    />
  );

  const renderRole = (name, value, isRoleFetching, isDisabledForLocationManager) => {
    if (isRoleFetching) {
      return <Loading />;
    }

    if (value === USER_ROLES.LOCATION_MANAGER) {
      if (isSelectedUserManager) {
        return (
          <Tooltip
            id="locationManagerRoleTooltip"
            placement="top"
            trigger={renderLocationManagerIcon}
          >
            <div>
              {isUserViewedCurrentUser ? 'You are' : 'The user is'} a bureau manager.
              There&apos;s no need to also be a Location Manager.
            </div>
          </Tooltip>
        );
      }

      return renderLocationManagerIcon;
    }

    return (
      <input
        checked={updatedUserRoles.has(value)}
        disabled={(isCurrentUserManager && selectedUserName) === currentUserName
          || isRoleSaving || isDisabledForLocationManager}
        name={value}
        onChange={event => handleToggleRole(event, name)}
        type="checkbox"
      />
    );
  };

  return (
    <>
      <FormGroup style={{ padding: '2rem 0' }}>
        <QrLogonCodeDisclaimerConfirmationModal
          show={showQrLogonCodeWarningDisclaimerModal}
          onConfirm={onUserConfirmGetContactlessLogonQRCode}
        />
        <QrLogonCodeModal
          show={showQrLogonCodeModal}
          onHide={() => setShowQrLogonCodeModal(false)}
          qrCode={qrCodeBase64Data}
          user={user}
        />
        <FormLabel>Roles</FormLabel>
        <div style={{ ...styles.spacingVertical }}>
          <div>
            {
              rolesToShow.map(({ name, value }) => {
                const isRoleFetching = roleFetchState?.role === value && roleFetchState.isFetching;
                // These roles are disabled for the Location Manager only if he does not have "Manager" higher role
                const isDisabledForLocationManager = !isCurrentUserManager && (isLocationManager
                  && disabledRolesForLocationManager.includes(value));
                return (
                  <InputGroup key={value} style={styles.spacingBottom}>
                    <InputGroup.Text className={value === USER_ROLES.LOCATION_MANAGER ? 'location-manager-text' : ''}>
                      {renderRole(name, value, isRoleFetching, isDisabledForLocationManager)}
                    </InputGroup.Text>
                    <FormControl type="text" value={name} disabled />
                  </InputGroup>
                );
              })
            }
          </div>
          <div className="pr-25">
            <Row>
              <Col xs={11} sm={11}>
                <SelectMultiple
                  title="Location"
                  data={locations}
                  labelKey="name"
                  valueKey="uri"
                  disabled={disableConditions || selectedUserRole === USER_ROLES.MANAGER}
                  multiple
                  selectAllOption
                  customOptionIcon={faUserTie}
                  customOptionIconReadOnly
                  availableData={locationManagerLocationUris}
                  selectedData={dataLocations}
                  handleOnClose={selected => onLocationsChange(selected)}
                />
              </Col>
              <Col xs={1} sm={1} className="d-flex justify-content-center align-items-center">
                {renderTooltipConditions('Location', conditionsRendering, {})}
              </Col>
            </Row>
            <div>
              <div style={styles.spacingTop}>
                <div className="d-flex align-items-center">
                  {
                    isRoleSaving ? (<Loading />) : (
                      <>
                        <Form.Check
                          type="checkbox"
                          style={{ margin: 0 }}
                          name="hideFinancials"
                          disabled={disableConditions || !userRoles.length || selectedUserRole === USER_ROLES.MANAGER}
                          checked={hideFinancialState}
                          onChange={toggleHideFinancials}
                          label="Hide Financials"
                        />
                        {renderTooltipConditions('Hide Financials', conditionsRendering, null, 'bottom')}
                      </>
                    )
                  }
                </div>
                <Form.Check
                  type="checkbox"
                  style={{ margin: 0 }}
                  name="agreedToTOS"
                  disabled
                  checked={user.tos}
                  label="Agreed to TOS"
                />
              </div>
              {
                confirmGlobal && (
                  <ConfirmationModal
                    handleCancel={handleDenySwitchingGlobalUserRole}
                    handleConfirm={handleConfirmGlobalUserRole}
                    message="Are you sure you would like to set the Global User role and select all locations?"
                  />
                )
              }
              {
                removingGlobal && (
                  <ConfirmationModal
                    handleCancel={() => setRemovingGlobal(false)}
                    handleConfirm={handleRemoveGlobalUserRole}
                    message="Are you sure you would like to remove all locations in the list as well as remove Global User role?"
                  />
                )
              }
            </div>
          </div>
        </div>
      </FormGroup>
      {
        isContactlessLoginEnabled && (
          <FormGroup>
            <FormLabel>Contactless Logon</FormLabel>
            <div>
              <Button
                disabled={isLoadingQRCodeData}
                onClick={() => setShowQrLogonCodeWarningDisclaimerModal(true)}
              >
                Get QR Logon Code
                <FontAwesomeIcon className="spacer-left" icon={faQrcode} />
              </Button>
            </div>
          </FormGroup>
        )
      }
      <div className="d-flex align-items-center justify-content-end">
        <div className="your-role-ui-container">
          <span className="your-role-ui-title">These are your roles:</span>
          <UserRolesBadges roles={[currentUserRole]} />
          <LocationUserRoleBadgeIcon
            locationRoles={currentUserLocations}
            locationsByUri={locationsByUri}
          />
          <LocationUserRoleBadgeIcon
            locationManager
            locationRoles={currentLocationManagerLocations}
            locationsByUri={locationsByUri}
          />
        </div>
      </div>
    </>
  );
};

ManageUser.propTypes = {
  userProps: PropTypes.shape({
    user: PropTypes.shape({
      tos: PropTypes.bool,
    }),
    rolesToShow: PropTypes.arrayOf(PropTypes.shape({})),
    roleFetchState: PropTypes.shape({
      role: PropTypes.string,
      isFetching: PropTypes.bool,
    }),
    updatedUserRoles: PropTypes.instanceOf(Set),
    isCurrentUserManager: PropTypes.bool,
    selectedUserName: PropTypes.string,
    currentUserName: PropTypes.string,
    handleToggleRole: PropTypes.func,
    isRoleSaving: PropTypes.bool,
    userRoles: PropTypes.arrayOf(PropTypes.shape({})),
    selectedUserRole: PropTypes.string,
    currentUserRole: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
    isLocationManager: PropTypes.bool,
    disabledRolesForLocationManager: PropTypes.arrayOf(PropTypes.string),
    handleToggleLocationManagerUI: PropTypes.func,
    locationManagerLocationRoles: PropTypes.arrayOf(PropTypes.shape({})),
    currentLocationManagerLocations: PropTypes.arrayOf(PropTypes.shape({})),
    selectedUserHighestRole: PropTypes.string.isRequired,
    isUserViewedCurrentUser: PropTypes.bool.isRequired,
  }).isRequired,
  roleProps: PropTypes.shape({
    confirmGlobal: PropTypes.bool,
    removingGlobal: PropTypes.bool,
    setRemovingGlobal: PropTypes.func,
    handleConfirmGlobalUserRole: PropTypes.func,
    handleRemoveGlobalUserRole: PropTypes.func,
    handleDenySwitchingGlobalUserRole: PropTypes.func,
    isLocationManager: PropTypes.bool,
    disabledRolesForLocationManager: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  locationProps: PropTypes.shape({
    locations: PropTypes.arrayOf(PropTypes.shape({})),
    disableConditions: PropTypes.bool,
    onLocationsChange: PropTypes.func,
    dataLocations: PropTypes.arrayOf(PropTypes.shape({})),
    locationsByUri: PropTypes.shape({}),
    currentUserLocations: PropTypes.arrayOf(PropTypes.shape({})),
    locationManagerLocationRoles: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  qrProps: PropTypes.shape({
    isContactlessLoginEnabled: PropTypes.bool,
    showQrLogonCodeWarningDisclaimerModal: PropTypes.bool,
    onUserConfirmGetContactlessLogonQRCode: PropTypes.func,
    showQrLogonCodeModal: PropTypes.bool,
    setShowQrLogonCodeModal: PropTypes.func,
    qrCodeBase64Data: PropTypes.string,
    isLoadingQRCodeData: PropTypes.bool,
    setShowQrLogonCodeWarningDisclaimerModal: PropTypes.func,
  }).isRequired,
  tooltipProps: PropTypes.shape({
    renderTooltipConditions: PropTypes.func,
    conditionsRendering: PropTypes.shape({}),
  }).isRequired,
  financialStateProps: PropTypes.shape({
    hideFinancialState: PropTypes.bool,
    toggleHideFinancials: PropTypes.func,
  }).isRequired,
};

export default ManageUser;
