import React from 'react';
import PropTypes from 'prop-types';

import { Container, ListGroup, ListGroupItem, Card } from 'react-bootstrap';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { FormattedMessage } from 'rapidfab/i18n';
import Feature from 'rapidfab/components/Feature';
import NonHawkingFeature from 'rapidfab/components/hawking/NonHawkingFeature';
import { FEATURES, ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { Outlet } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faList,
  faListAlt,
  faRefresh,
  faSliders,
  faTags,
  faUser,
  faUsersCog,
  faVial,
  faFile,
  faIndustry,
} from '@fortawesome/free-solid-svg-icons';
import { faCommenting } from '@fortawesome/free-regular-svg-icons';

const Admin = ({ bureauName, isHawkingUser, isLocationManager, shouldShowAdmin }) => (
  <Container fluid>
    <BreadcrumbNav breadcrumbs={['admin']} />
    <div className="page-header">
      <h1 className="font-size-36">
        <FormattedMessage
          id="bureauAdministration"
          defaultMessage="Bureau Administration"
        />
      </h1>
    </div>
    <Card bg={isHawkingUser ? 'light' : 'dark'}>
      <Card.Header className="pd-exp inverse">{bureauName}</Card.Header>
      <div className="card-body-wrapper">
        <Card.Body className="p-a-0">
          <ListGroup>
            {/* Users item us visible for the Manager and for the Location Manager users */}
            {(shouldShowAdmin || isLocationManager) && (
              <ListGroupItem action href={getRouteURI(ROUTES.ADMIN_USERS)}>
                <FontAwesomeIcon icon={faUser} className="spacer-right" fixedWidth />
                Users
              </ListGroupItem>
            )}

            {shouldShowAdmin && (
              <>
                <Feature featureNames={[FEATURES.STANLEY_X_DEPLOYMENT]} isInverted>
                  <Feature
                    featureNames={[
                      FEATURES.PREP_WORKFLOW,
                      FEATURES.GROUP_QUALIFICATIONS,
                      FEATURES.DIGITAL_DESIGN_WAREHOUSE,
                    ]}
                  >
                    <ListGroupItem action href={getRouteURI(ROUTES.ADMIN_USER_GROUPS)}>
                      <FontAwesomeIcon icon={faUsersCog} className="spacer-right" fixedWidth />
                      Groups
                    </ListGroupItem>
                  </Feature>

                  <ListGroupItem action href={getRouteURI(ROUTES.MANUFACTURERS)}>
                    <FontAwesomeIcon icon={faIndustry} className="spacer-right" fixedWidth />
                    <FormattedMessage
                      id="manage.manufacturers"
                      defaultMessage="Manufacturers"
                    />
                  </ListGroupItem>

                  <NonHawkingFeature>
                    <ListGroupItem action href={getRouteURI(ROUTES.ADMIN_BANNER)}>
                      <FontAwesomeIcon icon={faCommenting} className="spacer-right" fixedWidth />
                      Banner
                    </ListGroupItem>
                  </NonHawkingFeature>

                  <NonHawkingFeature>
                    <ListGroupItem action href={getRouteURI(ROUTES.ADMIN_RECALCULATION)}>
                      <FontAwesomeIcon icon={faRefresh} className="spacer-right" fixedWidth />
                      Recalculation Trigger
                    </ListGroupItem>
                  </NonHawkingFeature>

                </Feature>
                <ListGroupItem action href={getRouteURI(ROUTES.ADMIN_LABELS)}>
                  <FontAwesomeIcon icon={faTags} className="spacer-right" fixedWidth />
                  Labels
                </ListGroupItem>
                <Feature featureNames={[FEATURES.STANLEY_X_DEPLOYMENT]} isInverted>
                  <NonHawkingFeature>
                    <ListGroupItem action href={getRouteURI(ROUTES.ADMIN_SETTINGS)}>
                      <FontAwesomeIcon icon={faSliders} className="spacer-right" fixedWidth />
                      Settings
                    </ListGroupItem>
                  </NonHawkingFeature>
                  <ListGroupItem action href={getRouteURI(ROUTES.ADMIN_CUSTOM_FIELDS)}>
                    <FontAwesomeIcon icon={faList} className="spacer-right" fixedWidth />
                    Custom Fields
                  </ListGroupItem>
                </Feature>
                <Feature featureName={FEATURES.GUIDELINES_ENGINE}>
                  <ListGroupItem action href={getRouteURI(ROUTES.GUIDELINES_ENGINE)}>
                    <FontAwesomeIcon icon={faListAlt} className="spacer-right" fixedWidth />
                    <FormattedMessage
                      id="guidelinesEngine"
                      defaultMessage="Guidelines Engine"
                    />
                  </ListGroupItem>
                </Feature>
                <Feature featureName={FEATURES.MATERIAL_TEST_PANEL}>
                  <ListGroupItem action href={getRouteURI(ROUTES.MATERIAL_TESTS)}>
                    <FontAwesomeIcon icon={faVial} className="spacer-right" fixedWidth />
                    <FormattedMessage
                      id="materialTests"
                      defaultMessage="Material Tests"
                    />
                  </ListGroupItem>
                </Feature>
                <Feature featureName={FEATURES.SECURE_FILE_CHECKOUT}>
                  <ListGroupItem action href={getRouteURI(ROUTES.SECURE_FILE_CHECKOUT)}>
                    <FontAwesomeIcon icon={faFile} className="spacer-right" fixedWidth />
                    <FormattedMessage
                      id="secureRunCheckouts"
                      defaultMessage="Secure Run Checkouts"
                    />
                  </ListGroupItem>
                </Feature>
              </>
            )}
          </ListGroup>
        </Card.Body>
      </div>
    </Card>
    <Outlet />
  </Container>
);

Admin.propTypes = {
  bureauName: PropTypes.string.isRequired,
  isHawkingUser: PropTypes.bool.isRequired,
  isLocationManager: PropTypes.bool.isRequired,
  shouldShowAdmin: PropTypes.bool.isRequired,
};

export default Admin;
