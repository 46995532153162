import Permissions from 'rapidfab/permissions';
import { getCurrentUserRoleMax, getPermissions, getSession, isLocationManagerRole } from 'rapidfab/selectors';
import React from 'react';
import { useSelector } from 'react-redux';

import * as Selectors from 'rapidfab/selectors';

import { Admin } from 'rapidfab/components/admin';
import { FEATURES, USER_ROLES } from 'rapidfab/constants';

const AdminContainer = () => {
  const bureauName = useSelector(Selectors.getBureauName);
  const isHawkingUser = useSelector(state => Selectors.isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT));
  const permissions = useSelector(getPermissions);
  const session = useSelector(getSession);
  const highestUserRole = useSelector(getCurrentUserRoleMax);
  const isCurrentUserManager = highestUserRole?.role === USER_ROLES.MANAGER;
  const isLocationManager = useSelector(isLocationManagerRole);

  const sessionWithPermissions = {
    ...session,
    permissions,
  };

  // The full Administration Menu Items will be visible only if:
  // - You have the ``administrate.group`` permission + you are a ``manager``
  const shouldShowAdmin = Permissions.has(
    'nautilus',
    'administrate.group',
    sessionWithPermissions,
  ) && isCurrentUserManager;

  return (
    <Admin
      bureauName={bureauName}
      isHawkingUser={isHawkingUser}
      isLocationManager={isLocationManager}
      shouldShowAdmin={shouldShowAdmin}
    />
  );
};

export default AdminContainer;
