import _capitalize from 'lodash/capitalize';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import LocationSettings from 'rapidfab/components/organize/LocationSettings';
import PhoneInput from 'rapidfab/components/PhoneInput';
import SubLocations from 'rapidfab/components/records/SubLocations';
import LocationResources from 'rapidfab/components/LocationResources';
import UserOption from 'rapidfab/components/UserOption';
import { resetRcTooltipInnerStyle, selectInputStyles } from 'rapidfab/constants/styles';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import RCTooltip from 'rc-tooltip';
import Tooltip from 'rapidfab/components/Tooltip';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  FormGroup,
  FormLabel,
  FormControl,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip as BSTooltip,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import {
  LOCATION_TABS,
} from 'rapidfab/constants';
import { FormControlSelect } from 'rapidfab/components/formTools';
import { finalFormInputTypes, locationSettingsResourceType } from 'rapidfab/types';
import Loading from 'rapidfab/components/Loading';
import { Form, Field } from 'react-final-form';
import {
  faClose,
  faFloppyDisk,
  faInfoCircle, faLocationDot, faLock,
  faPlus, faTrash,
} from '@fortawesome/free-solid-svg-icons';

import 'rapidfab/styles/componentStyles/custom-darken-modal.scss';
import Select from 'react-select';

const locationNameTooltipLength = 80;

const LocationModal = ({
  onDelete,
  users,
  countries,
  updatedLocationSettings,
  onChangeLocationSettings,
  onSubmit,
  isSubmitting,
  initialFormValues,
  isVisible,
  uuid,
  handleCloseModal,
  fetching,
  deleting,
  allEmailNotificationsActive,
  handleEmailNotificationsModeSetting,
  usersFetching,
  locationSettingsFetching,
  activeTab,
  setActiveTab,
  renderResourceCount,
  locationUri,
  handleSetLocationFilters,
  materialResourcesFetching,
  setAddSubLocationModal,
  addSubLocationModal,
  subLocationsFetching,
  subLocations,
  isMaterialManagementFeatureEnabled,
  isCurrentUserHighestRoleLocationManager,
  ...subLocationProps
}) => {
  const [confirmDelete, setConfirmDelete] = React.useState(false);

  const handleConfirmDeleteLocation = () => {
    setConfirmDelete(false);
    return onDelete(initialFormValues?.uuid);
  };

  const formattedContacts = users.map(user => ({
    label: UserOption.generateLabel(user),
    value: user.uri,
  })).filter(Boolean);

  return (
    <Modal
      size="lg"
      show={isVisible || uuid || initialFormValues?.uuid}
      onHide={handleCloseModal}
      scrollable
      backdrop="static"
      className="location-modal"
      dialogClassName="custom-darken-modal location-modal-dialog"
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        initialValuesEqual={isEqual}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Header className="mb25">
              <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faLocationDot} />

                  <p className="custom-darken-modal-title-text">
                    {initialFormValues?.uuid && initialFormValues?.name ?
                      (
                        initialFormValues.name.length >= locationNameTooltipLength ?
                          (
                            <div className="d-flex align-items-center">
                              <RCTooltip
                                placement="bottom"
                                id="printerTypeNameTooltip"
                                destroyTooltipOnHide
                                overlayInnerStyle={resetRcTooltipInnerStyle}
                                mouseLeaveDelay={0.4}
                                overlay={(
                                  <p className="mb0 darkTooltip">
                                    {_capitalize(initialFormValues.name)}
                                  </p>
                                )}
                              >
                                <div className="d-flex align-items-center">
                                  <p className="mb0 truncate capitalize custom-darken-modal-title-text-title">
                                    {_capitalize(initialFormValues.name)}
                                  </p>
                                  ({getShortUUID(initialFormValues.uuid)})
                                </div>
                              </RCTooltip>
                            </div>
                          ) : (
                            <span>{_capitalize(initialFormValues.name)}
                                        &nbsp;({getShortUUID(initialFormValues.uuid)})
                            </span>
                          )
                      ) : (
                        <>
                          <FormattedMessage
                            id="field.location.new"
                            defaultMessage="New Location"
                          />
                          <OverlayTrigger
                            placement="bottom"
                            overlay={(
                              <BSTooltip>
                                <p>
                                  <FormattedMessage
                                    id="record.location.jumbotron"
                                    defaultMessage="Update or add Manufacturing Location (Location) details.  You can limit Orders, Print Runs, or Users to specific locations."
                                  />
                                </p>
                              </BSTooltip>
                            )}
                          >
                            <FontAwesomeIcon className="pr-1 spacer-left" icon={faInfoCircle} />
                          </OverlayTrigger>
                        </>
                      )}
                  </p>
                  {isCurrentUserHighestRoleLocationManager && (
                    <Tooltip
                      id="accessDisallowedReason"
                      placement="bottom"
                      trigger={(<FontAwesomeIcon icon={faLock} color="#FFA500" className="spacer-left" />)}
                    >
                      <div className="mb3">You do not have permission to change this because you are a Location Manager.</div>
                      <div>Speak with a Manager user to have this value changed.</div>
                    </Tooltip>
                  )}
                </div>
                <FontAwesomeIcon
                  icon={faClose}
                  onClick={handleCloseModal}
                  tabIndex={0}
                  role="button"
                  className="custom-darken-modal-button"
                />
              </Modal.Title>
            </Modal.Header>
            <div className="location-modal-tabs" style={{ marginBottom: activeTab === LOCATION_TABS.DETAILS ? '15px' : '0' }}>
              <Tabs
                activeKey={activeTab}
                onSelect={tabName => setActiveTab(tabName)}
                id="locationTabs"
              >
                <Tab eventKey={LOCATION_TABS.DETAILS} title="Details" tabClassName="location-modal-tab" />
                {(!!subLocations?.length || (!subLocations?.length && subLocationsFetching)) && (
                  <Tab
                    eventKey={LOCATION_TABS.SUB_LOCATIONS}
                    title={(
                      <>
                        {subLocationsFetching && (<Loading inline className="spacer-right" />)}
                        <span>Sub Locations</span>
                      </>

                    )}
                    tabClassName="location-modal-tab"
                    disabled={fetching || (!initialFormValues?.uuid && !initialFormValues?.name)}
                  />
                )}
                {uuid && isMaterialManagementFeatureEnabled && (
                  <Tab eventKey={LOCATION_TABS.RESOURCES} title="Resources" tabClassName="location-modal-tab" />
                )}
              </Tabs>
              {activeTab === LOCATION_TABS.SUB_LOCATIONS && (
                <Button
                  variant="link"
                  size="sm"
                  className="location-modal-tabs-sub-location-add-btn"
                  onClick={() => setAddSubLocationModal(true)}
                  disabled={!uuid || fetching || isCurrentUserHighestRoleLocationManager}
                >
                  <FontAwesomeIcon icon={faPlus} className="spacer-right" />
                  <FormattedMessage id="button.add" defaultMessage="Add" />
                </Button>
              )}
            </div>
            {activeTab === LOCATION_TABS.DETAILS && (
              fetching ?
                <Loading className="p-a-md" /> :
                (
                  <>
                    <Modal.Body className="custom-darken-modal--body-scroll location-modal-body">
                      <Row className="custom-darken-modal-custom-gutter">
                        <Col lg={6} xs={12}>

                          <FormGroup className="form-group" controlId="uxName">
                            <FormLabel>
                              <FormattedMessage id="field.name" defaultMessage="Name" />: *
                            </FormLabel>
                            <Field
                              name="name"
                              type="text"
                              render={props => (
                                <FormControl
                                  {...props.input}
                                  disabled={isCurrentUserHighestRoleLocationManager}
                                  required
                                />
                              )}
                            />
                          </FormGroup>
                          <FormGroup className="form-group" controlId="uxAddress">
                            <FormLabel>
                              <FormattedMessage id="field.address" defaultMessage="Address" />:
                              *
                            </FormLabel>
                            <Field
                              name="address"
                              type="text"
                              render={props => (
                                <FormControl
                                  {...props.input}
                                  disabled={isCurrentUserHighestRoleLocationManager}
                                  as="textarea"
                                  required
                                />
                              )}
                            />
                          </FormGroup>
                          <FormGroup className="form-group" controlId="uxCountries">
                            <FormLabel>
                              <FormattedMessage id="field.country" defaultMessage="Country" />:
                              *
                            </FormLabel>
                            <Field
                              name="countries_served"
                              render={props => (
                                <FormControlSelect
                                  {...props.input}
                                  disabled={isCurrentUserHighestRoleLocationManager}
                                  required
                                >
                                  <option key="placeholder" value="" disabled>
                                    Select a country
                                  </option>
                                  {countries.map(country => (
                                    <option key={country.code} value={country.code}>
                                      {country.name}
                                    </option>
                                  ))}
                                </FormControlSelect>
                              )}
                            />
                          </FormGroup>
                          <FormGroup className="form-group" controlId="uxPhone">
                            <FormLabel>
                              <FormattedMessage id="field.phone" defaultMessage="Phone" />:
                            </FormLabel>
                            <Field
                              name="phone"
                              type="tel"
                              render={({ input }) => (
                                <PhoneInput {...input} disabled={isCurrentUserHighestRoleLocationManager} />
                              )}
                            />
                          </FormGroup>

                          <FormGroup className="form-group" controlId="uxContact">
                            <FormLabel>
                              <FormattedMessage id="field.contact" defaultMessage="Contact" />:
                            </FormLabel>
                            <Field
                              name="contact"
                              render={({ input }) => {
                                const selectedOption = _find(formattedContacts,
                                  { value: input.value }) || null;

                                return (
                                  <Select
                                    options={formattedContacts}
                                    onChange={option =>
                                      input.onChange(option?.value || null)}
                                    placeholder="Select a Contact"
                                    components={{ LoadingIndicator: () => (<Loading inline className="spacer-right" />) }}
                                    isLoading={usersFetching}
                                    value={selectedOption}
                                    isDisabled={isCurrentUserHighestRoleLocationManager}
                                    styles={selectInputStyles}
                                    isClearable
                                  />
                                );
                              }}
                            />
                          </FormGroup>

                        </Col>

                        <Col lg={6} xs={12}>
                          <FormGroup className="form-group" controlId="uxLocationSettings">
                            {
                              ((_isEmpty(updatedLocationSettings) && !isVisible)
                              && !locationSettingsFetching)
                            || locationSettingsFetching
                                ? <Loading />
                                : (
                                  <LocationSettings
                                    handleEmailNotificationsModeSetting={handleEmailNotificationsModeSetting}
                                    allEmailNotificationsActive={allEmailNotificationsActive}
                                    updatedLocationSettings={updatedLocationSettings}
                                    onChangeLocationSettings={onChangeLocationSettings}
                                    isCustomDarkCardStyle
                                    disabled={isCurrentUserHighestRoleLocationManager}
                                  />
                                )
                            }
                          </FormGroup>
                        </Col>
                      </Row>
                    </Modal.Body>
                    <Modal.Footer>

                      {
                        initialFormValues?.id && (
                          <Button
                            disabled={isSubmitting || deleting || isCurrentUserHighestRoleLocationManager}
                            variant="danger"
                            onClick={() => setConfirmDelete(true)}
                          >
                            {deleting ? <Loading /> : (
                              <>
                                <FontAwesomeIcon icon={faTrash} className="spacer-right" />
                                <FormattedMessage id="button.delete" defaultMessage="Delete" />
                              </>
                            )}
                          </Button>
                        )
                      }

                      <Button type="submit" disabled={isSubmitting || isCurrentUserHighestRoleLocationManager} variant="success">
                        {isSubmitting ? <Loading /> : (
                          <>
                            <FontAwesomeIcon icon={initialFormValues?.id ? faFloppyDisk : faPlus} className="spacer-right" />
                            <FormattedMessage
                              id={initialFormValues?.id ? 'button.save' : 'button.createResource'}
                              defaultMessage={initialFormValues?.id ? 'Save' : 'Create'}
                            />
                          </>
                        )}
                      </Button>

                    </Modal.Footer>
                  </>
                )
            )}

            {activeTab === LOCATION_TABS.SUB_LOCATIONS && (
              <Modal.Body className="custom-darken-modal--body-scroll locations-modal-body">
                <Row className="custom-darken-modal-custom-gutter">
                  <SubLocations
                    locationUri={locationUri}
                    handleSetLocationFilters={handleSetLocationFilters}
                    materialResourcesFetching={materialResourcesFetching}
                    renderResourceCount={renderResourceCount}
                    setAddSubLocationModal={setAddSubLocationModal}
                    addSubLocationModal={addSubLocationModal}
                    subLocationsFetching={subLocationsFetching}
                    subLocations={subLocations}
                    isMaterialManagementFeatureEnabled={isMaterialManagementFeatureEnabled}
                    isDisabled={isCurrentUserHighestRoleLocationManager}
                    {...subLocationProps}
                  />
                </Row>
              </Modal.Body>
            )}

            {activeTab === LOCATION_TABS.RESOURCES && (
              <LocationResources
                locationUri={locationUri}
                renderResourceCount={renderResourceCount}
                materialResourcesFetching={materialResourcesFetching}
                handleSetLocationFilters={handleSetLocationFilters}
              />
            )}

            {confirmDelete && (
              <ConfirmationModal
                handleCancel={() => setConfirmDelete(false)}
                handleConfirm={handleConfirmDeleteLocation}
                message={(
                  <FormattedMessage
                    id="message.entityDeletePrompt"
                    defaultMessage="Are you sure you want to delete this {entityType}?"
                    values={{ entityType: 'Location' }}
                  />
                )}
                confirmButtonContent={<FormattedMessage id="button.delete" defaultMessage="Delete" />}
              />
            )}

          </form>
        )}
      />
    </Modal>
  );
};

LocationModal.propTypes = {
  initialFormValues: PropTypes.shape({
    contact: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    countries_served: PropTypes.arrayOf(PropTypes.string).isRequired,
    phone: PropTypes.string.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updatedLocationSettings: locationSettingsResourceType.isRequired,
  onChangeLocationSettings: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  input: finalFormInputTypes.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  uuid: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  deleting: PropTypes.bool.isRequired,
  allEmailNotificationsActive: PropTypes.bool.isRequired,
  handleEmailNotificationsModeSetting: PropTypes.func.isRequired,
  usersFetching: PropTypes.bool.isRequired,
  locationSettingsFetching: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  renderResourceCount: PropTypes.func.isRequired,
  locationUri: PropTypes.string.isRequired,
  handleSetLocationFilters: PropTypes.func.isRequired,
  materialResourcesFetching: PropTypes.bool.isRequired,
  setAddSubLocationModal: PropTypes.func.isRequired,
  addSubLocationModal: PropTypes.bool.isRequired,
  subLocationsFetching: PropTypes.bool.isRequired,
  subLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMaterialManagementFeatureEnabled: PropTypes.bool.isRequired,
  isCurrentUserHighestRoleLocationManager: PropTypes.bool.isRequired,
};

LocationModal.defaultProps = {};

export default LocationModal;
