import { faExclamationCircle, faQuestionCircle, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Feature from 'rapidfab/components/Feature';
import FormRow from 'rapidfab/components/FormRow';
import ModalThreeScene from 'rapidfab/components/ModalThreeScene';
import VisibleFor from 'rapidfab/components/VisibleFor';
import CustomFieldComponent from 'rapidfab/components/forms/CustomFieldComponent';
import CustomFieldList from 'rapidfab/components/forms/CustomFieldList';
import SelectSingle from 'rapidfab/components/forms/SelectSingle';
import SelectSingleLazy from 'rapidfab/components/forms/SelectSingleLazy';
import FileInput from 'rapidfab/components/records/order/edit/FileInput';
import {
  BEEHIVE_LINE_ITEM_CUSTOM_FIELDS,
  COLORS,
  FEATURES,
  MODEL_UNITS,
  MODEL_USER_UNITS_BY_FEATURE,
  WORKFLOW_USAGE_STATES,
} from 'rapidfab/constants';
import usePrevious from 'rapidfab/hooks/usePrevious';
import { FormattedMessageMappingOption } from 'rapidfab/i18n';
import {
  MODEL_UNITS_MAP,
  MODEL_UNITS_MAP_IMPERIAL,
  MODEL_UNITS_MAP_METRIC,
  MODEL_UNITS_MAP_VIEW_MODEL_IN,
} from 'rapidfab/mappings';
import { modelType, orderType } from 'rapidfab/types';
import getVisibleCustomFieldReferencesWithOptions from 'rapidfab/utils/getVisibleCustomFieldReferencesWithOptions';
import getlineItemCustomFieldReferencesOverrides from 'rapidfab/utils/lineItemCustomFieldReferencesOverrides';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  ButtonToolbar,
  Col,
  Form,
  FormControl,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const AddLineItemPresentation = props => {
  const {
    model,
    baseMaterial,
    baseMaterials,
    customFieldValues,
    customLineItemFieldReferences,
    customOrderFieldReferences,
    documents,
    handleDocumentDelete,
    handleDocumentChange,
    handleInputChange,
    partName,
    customerId,
    onSubmit,
    onCustomFieldChange,
    notes,
    quantity,
    submitting,
    fetching,
    supportMaterial,
    supportMaterials,
    workflow,
    workflows,
    canSelectStrategies,
    supportStrategies,
    infillStrategies,
    supportStrategy,
    infillStrategy,
    options,
    isUserRestricted,
    modelFileUnits,
    modelUserUnits,
    order,
    locations,
    onFetchMoreWorkflows,
    isPowderWorkflow,
    isPOCUKOrderFieldsFeatureEnabled,
  } = props;

  const isSubmittingButtonDisabled = submitting;
  const disableFormControls = submitting;

  const showModelUserUnits = useMemo(() => {
    if (isPOCUKOrderFieldsFeatureEnabled) {
      return MODEL_USER_UNITS_BY_FEATURE[FEATURES.POC_UK_ORDER_FIELDS];
    }
    return Object.keys(MODEL_UNITS_MAP_VIEW_MODEL_IN);
  }, [isPOCUKOrderFieldsFeatureEnabled]);

  const fieldOverrides = getlineItemCustomFieldReferencesOverrides({
    isNormalUser: !isUserRestricted,
  });

  const previousWorkflow = usePrevious(workflow);
  const [invalidFields, setInvalidFields] = useState([]);

  const addLineItemPresentationFormRef = useRef(null);
  const workflowFieldRef = useRef(null);

  const visiblelineItemCustomFieldReferencesWithOptions =
    getVisibleCustomFieldReferencesWithOptions({
      fieldReferences: customLineItemFieldReferences,
      fieldValues: customFieldValues,
      fieldOverrides,
      parentFieldReferences: customOrderFieldReferences,
      parentFieldValues: order.custom_field_values,
    });

  const revisionFieldReference = _find(customLineItemFieldReferences, {
    field_id: BEEHIVE_LINE_ITEM_CUSTOM_FIELDS.REVISION,
  });
  const revisionFieldValue = _find(
    customFieldValues,
    ({ custom_field }) =>
      revisionFieldReference && custom_field === revisionFieldReference.uri,
  );

  // Overlay callback is needed since tooltip has no access to intl
  const overlayCallback = tootltipContent => (
    <OverlayTrigger
      placement="left"
      overlay={
        <Tooltip id="locationFilteringMessage">{tootltipContent}</Tooltip>
      }
    >
      <FontAwesomeIcon icon={faQuestionCircle} className="spacer-left" />
    </OverlayTrigger>
  );

  // Reset previously invalid field styles on interaction.
  const handleResetInvalidFields = () => {
    if (previousWorkflow !== workflow && workflowFieldRef?.current) {
      workflowFieldRef.current.node.style.removeProperty('border');
    }

    if (invalidFields.length) {
      invalidFields.forEach(field => {
        field.addEventListener('focus', () => {
          field.style.removeProperty('border');
        });
      });
    }
  };

  useEffect(() => {
    handleResetInvalidFields();
  }, [invalidFields, workflow]);

  const validateForm = (event, handleSubmitCallback) => {
    event.preventDefault();

    // Reset previously invalid fields' styling.
    invalidFields.forEach(field => {
      field.style.removeProperty('border');
      field.style.removeProperty('border-radius');
    });

    const updatedInvalidFields = [];
    let isValid = true;

    Object.entries(options).forEach(([fieldName, value]) => {
      if (value.show && value.required && _isEmpty(props[fieldName])) {
        isValid = false;

        const invalidField = addLineItemPresentationFormRef.current.querySelector(`[name="${fieldName}"]`);
        if (invalidField) updatedInvalidFields.push(invalidField);
        if (fieldName === 'workflow' && workflowFieldRef?.current) {
          updatedInvalidFields.push(workflowFieldRef.current.node);
        }
        setInvalidFields(updatedInvalidFields);
      }
    });

    // Add red border styling to invalid fields.
    updatedInvalidFields.forEach(field => {
      field.style.setProperty('border', `1px solid ${COLORS.RED}`, 'important');
      field.style.setProperty('border-radius', '4px');
    });

    // Scroll to first item of invalid fields.
    if (updatedInvalidFields.length) {
      const invalidFieldYOffset = updatedInvalidFields[0].getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: invalidFieldYOffset - 80, behavior: 'smooth' });
    }

    if (isValid) {
      setInvalidFields([]);
      handleSubmitCallback(event);
    }
  };

  return (
    <Form
      id="add-line-item-presentation-form"
      ref={addLineItemPresentationFormRef}
      horizontal
      onSubmit={event => validateForm(event, onSubmit)}
    >
      <div>
        <FormRow id="field.model" defaultMessage="Model">
          <Form.Text>
            {model ? (
              <>
                {model.name}
                <div style={{ maxWidth: '200px' }}>
                  <ModalThreeScene
                    showMfgOrientationPanel={false}
                    snapshot={model.snapshot_content || 'LOADING'}
                    model={model.content}
                    unit={model.user_unit}
                    fileUnit={model.file_unit}
                    size={model.size}
                    volume={model.volume_mm}
                  />
                </div>
              </>
            ) : (
              <FormattedMessage
                id="record.uploadWithoutDesign"
                defaultMessage="Upload Without Design"
              />
            )}
          </Form.Text>
        </FormRow>
        <div>
          <Feature featureName={FEATURES.LINE_ITEM_EXTENDED_DETAILS}>
            <>
              <FormRow id="field.partName" defaultMessage="Part Name">
                <FormControl
                  name="partName"
                  value={partName}
                  type="text"
                  onChange={handleInputChange}
                  data-cy="add-cad-partName"
                />
              </FormRow>
              <FormRow
                id={options.customerID.id}
                defaultMessage={options.customerID.defaultMessage}
              >
                <FormControl
                  name="customerID"
                  value={customerId}
                  type="text"
                  onChange={handleInputChange}
                  data-cy="add-cad-customerId"
                />
              </FormRow>
            </>
          </Feature>
          {revisionFieldReference && (
            <CustomFieldComponent
              reference={revisionFieldReference}
              value={revisionFieldValue && revisionFieldValue.value}
              onChange={onCustomFieldChange}
              variant={CustomFieldComponent.variants.form}
            />
          )}
        </div>

        {options.baseMaterial.show && (
          <FormRow
            labelSuffix={
              baseMaterial &&
              workflows.length === 0 &&
              !fetching && (
                <OverlayTrigger
                  overlay={(
                    <Tooltip>
                      <FormattedMessage
                        id="noMatchingWorkflowForMaterial"
                        defaultMessage="No workflow matches selected material at your current location."
                      />
                    </Tooltip>
                  )}
                  placement="top"
                  show={baseMaterial && workflows.length === 0}
                >
                  <FontAwesomeIcon className="spacer-left" icon={faExclamationCircle} />
                </OverlayTrigger>
              )
            }
            id="field.material"
            defaultMessage="Material"
            isRequired
          >
            <div className="d-flex align-items-center">
              <SelectSingle
                name="baseMaterial"
                data={baseMaterials}
                value={baseMaterial}
                placeholder={
                  baseMaterials.length > 0
                    ? 'Select material'
                    : 'No materials available for this location'
                }
                handleOnChange={handleInputChange}
                required
                imitateOnChangeEvent
                disabled={disableFormControls}
              />
              <div className="spacer-left spacer-right">at</div>
              <SelectSingle
                name="location"
                data={locations}
                value={order.location}
                handleOnChange={() => {}}
                disabled
              />
              <FormattedMessage
                id="record.lineItem.locationFilterMessage"
                defaultMessage="The Materials you can select from are those Materials which are available at the Order's Location. To see other Materials that may be available, please change the Order's Location. To see All Materials available in your bureau, set the Order Location to Any."
              >
                {text => overlayCallback(text)}
              </FormattedMessage>
            </div>
          </FormRow>
        )}

        <FormRow
          id="modelFileUnits"
          defaultMessage="Model File Units"
          isRequired
        >
          <FormControl
            name="modelFileUnits"
            value={modelFileUnits}
            as="select"
            onChange={handleInputChange}
            required
            data-cy="add-cad-modelFileUnits"
          >
            <FormattedMessageMappingOption
              mapping={MODEL_UNITS_MAP}
              value="Automatic"
              key="automatic"
            />
            <optgroup label="Metric">
              {Object.keys(MODEL_UNITS_MAP_METRIC).map(modelUnit => (
                <FormattedMessageMappingOption
                  mapping={MODEL_UNITS_MAP_METRIC}
                  value={modelUnit}
                  key={modelUnit}
                />
              ))}
            </optgroup>
            {!isPOCUKOrderFieldsFeatureEnabled && (
              <optgroup label="Imperial">
                {Object.keys(MODEL_UNITS_MAP_IMPERIAL).map(modelUnit => (
                  <FormattedMessageMappingOption
                    mapping={MODEL_UNITS_MAP_IMPERIAL}
                    value={modelUnit}
                    key={modelUnit}
                  />
                ))}
              </optgroup>
            )}
          </FormControl>
        </FormRow>

        <FormRow
          id="field.viewModelIn"
          defaultMessage="View Model In"
          isRequired
        >
          <FormControl
            name="modelUserUnits"
            value={modelUserUnits}
            as="select"
            onChange={handleInputChange}
            required
            data-cy="add-cad-modelUserUnits"
          >
            {showModelUserUnits.map(modelUnit => (
              <FormattedMessageMappingOption
                mapping={MODEL_UNITS_MAP_VIEW_MODEL_IN}
                value={modelUnit}
                key={modelUnit}
              />
            ))}
          </FormControl>
        </FormRow>

        {options.supportMaterial.show && (
          <FormRow id="field.supportMaterial" defaultMessage="Support Material">
            <SelectSingle
              name="supportMaterial"
              data={supportMaterials}
              value={supportMaterial}
              handleOnChange={handleInputChange}
              imitateOnChangeEvent
              disabled={disableFormControls}
            />
          </FormRow>
        )}

        <FormRow id="field.quantity" defaultMessage="Quantity" isRequired>
          <FormControl
            name="quantity"
            type="number"
            min="1"
            required
            onChange={handleInputChange}
            value={quantity}
            disabled={disableFormControls}
          />
        </FormRow>
        {options.workflow.show && (
          <FormRow
            id="workflow"
            defaultMessage="Production Workflow"
            isRequired={options.workflow.required}
          >
            <SelectSingleLazy
              ref={workflowFieldRef}
              name="workflow"
              dataTestId="lineItemBaseMaterialPresentation"
              placeholder={
                baseMaterial ? 'Choose...' : 'Please select a base material'
              }
              data={workflows}
              value={workflow}
              handleOnChange={handleInputChange}
              imitateOnChangeEvent
              disabled={disableFormControls}
              isOptionDisabledCallback={item =>
                item.usage_state === WORKFLOW_USAGE_STATES.ARCHIVED}
              onFetchMore={onFetchMoreWorkflows}
            />
          </FormRow>
        )}

        <FormRow id="field.notes" defaultMessage="Notes">
          <FormControl
            name="notes"
            value={notes}
            as="textarea"
            placeholder={options.notes.placeholder}
            onChange={handleInputChange}
          />
        </FormRow>

        {options.infillStrategy.show && canSelectStrategies && (
          <Feature featureName={FEATURES.EXTERNAL_PRODUCTION_ESTIMATE}>
            <FormRow
              id="field.infill_strategy"
              defaultMessage="Infill Strategy"
            >
              <FormControl
                name="infill_strategy"
                as="select"
                onChange={handleInputChange}
                value={infillStrategy}
                disabled={disableFormControls}
              >
                {infillStrategies.map(infillStrategyOption => (
                  <option
                    key={infillStrategyOption.uri}
                    value={infillStrategyOption.uri}
                  >
                    {infillStrategyOption.name}
                  </option>
                ))}
              </FormControl>
            </FormRow>
          </Feature>
        )}

        {options.supportStrategy.show && canSelectStrategies && (
          <Feature featureName={FEATURES.EXTERNAL_PRODUCTION_ESTIMATE}>
            <FormRow
              id="field.support_strategy"
              defaultMessage="Support Strategy"
            >
              <FormControl
                name="support_strategy"
                as="select"
                onChange={handleInputChange}
                value={supportStrategy}
                disabled={disableFormControls}
              >
                {supportStrategies.map(supportStrategyOption => (
                  <option
                    key={supportStrategyOption.uri}
                    value={supportStrategyOption.uri}
                  >
                    {supportStrategyOption.name}
                  </option>
                ))}
              </FormControl>
            </FormRow>
          </Feature>
        )}

        <CustomFieldList
          customFieldReferences={
            visiblelineItemCustomFieldReferencesWithOptions
          }
          customFieldValues={customFieldValues}
          onChange={onCustomFieldChange}
        />

        {!isPowderWorkflow && (
          <VisibleFor unrestricted>
            <Feature featureName={FEATURES.AUTORUN}>
              <FormRow id="field.autorun" defaultMessage="Auto-create Run">
                <Form.Check
                  name="autorun"
                  type="checkbox"
                  onChange={handleInputChange}
                  disabled={disableFormControls}
                />
              </FormRow>
            </Feature>
          </VisibleFor>
        )}

        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Col md={3}>
            <FileInput
              fileType={FileInput.fileTypes.document}
              handleFileChange={handleDocumentChange}
              disabled={disableFormControls}
            />
          </Col>
          <Col md={9}>
            {documents.map((document, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={String(index)}>
                {`${document.name} `}
                <Button
                  size="xs"
                  className="m-r"
                  onClick={() => handleDocumentDelete(index)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </span>
            ))}
          </Col>
        </Row>

        <ButtonToolbar className="clearfix pull-right">
          <Button
            disabled={isSubmittingButtonDisabled}
            type="submit"
            variant="success"
            className="pull-right"
            style={{ marginTop: '2rem' }}
          >
            {' '}
            {submitting ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <FormattedMessage
                id="record.lineItem.saveDetails"
                defaultMessage="Save Line Item Details"
              />
            )}
          </Button>
        </ButtonToolbar>
      </div>
    </Form>
  );
};

const optionType = {
  id: PropTypes.string,
  defaultMessage: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
};

AddLineItemPresentation.propTypes = {
  baseMaterial: PropTypes.string,
  baseMaterials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customFieldValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customLineItemFieldReferences: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  customOrderFieldReferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleDocumentDelete: PropTypes.func.isRequired,
  handleDocumentChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  partName: PropTypes.string,
  customerId: PropTypes.string,
  notes: PropTypes.string,
  modelFileUnits: PropTypes.string,
  modelUserUnits: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modelLibrary: PropTypes.shape({
    name: PropTypes.string,
  }),
  onCustomFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  quantity: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  supportMaterial: PropTypes.string.isRequired,
  supportMaterials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  workflow: PropTypes.string,
  workflows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  canSelectStrategies: PropTypes.bool.isRequired,
  supportStrategy: PropTypes.string,
  infillStrategy: PropTypes.string,
  supportStrategies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  infillStrategies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  uploadModel: PropTypes.shape({
    name: PropTypes.string,
    percent: PropTypes.number,
  }),
  options: PropTypes.shape({
    workflow: optionType,
    notes: optionType,
    customerID: optionType,
    modelFileUnits: optionType,
    modelUserUnits: optionType,
    baseMaterial: optionType,
    supportMaterial: optionType,
    infillStrategy: optionType,
    supportStrategy: optionType,
  }).isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  order: orderType.isRequired,
  // Can be null when `no_model_upload` is selected
  model: modelType,
  onFetchMoreWorkflows: PropTypes.func,
  isPowderWorkflow: PropTypes.bool.isRequired,
  isPOCUKOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
};

AddLineItemPresentation.defaultProps = {
  baseMaterial: null,
  uploadModel: null,
  notes: null,
  modelFileUnits: MODEL_UNITS.AUTOMATIC,
  modelUserUnits: MODEL_UNITS.AUTOMATIC,
  partName: null,
  customerId: null,
  modelLibrary: null,
  supportStrategy: null,
  infillStrategy: null,
  workflow: null,
  model: null,
  onFetchMoreWorkflows: null,
};

export default AddLineItemPresentation;
