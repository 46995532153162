import { USER_ROLES } from 'rapidfab/constants';

/*
 * Router bitmask permissions to use.
 * When you extend it, please make sure that general role is 2^x
 * and "ALL" permission includes new role
 */
export const ROUTER_PERMISSION = {
  GUEST: 1, // 2^0
  RESTRICTED: 2, // 2^1
  SELF_REGISTERED_GUEST: 4, // 2^2
  LOCATION_USER: 8, // 2^3
  SERVICE_PROVIDER: 16, // 2^4
  UNKNOWN: 32, // 2^5
  MANAGER: 64, // 2^6
  GLOBAL_USER: 128, // 2^7
  STANDARD_USER: 256, // 2^8
  VENDOR_SERVICE_PROVIDER: 512, // 2^9
  LOCATION_MANAGER: 1024, // 2^10
  //
  // Mixed permissions:
  //
  // Sum of all permissions, permissions to anyone for router
  ALL: 1024 + 512 + 256 + 128 + 64 + 32 + 16 + 8 + 4 + 2 + 1,
  LOGGED_IN_ONLY: 1024 + 512 + 256 + 128 + 64 + 32 + 16 + 8 + 4 + 2,
  BUREAU_ONLY: 1024 + 256 + 128 + 64 + 32 + 8 + 4 + 2,
  BUREAU_UNRESTRICTED: 1024 + 256 + 128 + 64 + 32,
};

export const ROLE_TO_PERMISSION = {
  [USER_ROLES.UNKNOWN]: ROUTER_PERMISSION.UNKNOWN,
  [USER_ROLES.SELF_REGISTERED_GUEST]: ROUTER_PERMISSION.SELF_REGISTERED_GUEST,
  [USER_ROLES.RESTRICTED]: ROUTER_PERMISSION.RESTRICTED,
  [USER_ROLES.LOCATION_USER]: ROUTER_PERMISSION.LOCATION_USER,
  [USER_ROLES.GLOBAL_USER]: ROUTER_PERMISSION.GLOBAL_USER,
  [USER_ROLES.STANDARD_USER]: ROUTER_PERMISSION.STANDARD_USER,
  [USER_ROLES.MANAGER]: ROUTER_PERMISSION.MANAGER,
  [USER_ROLES.LOCATION_MANAGER]: ROUTER_PERMISSION.LOCATION_MANAGER,
  [USER_ROLES.SERVICE_PROVIDER]: ROUTER_PERMISSION.SERVICE_PROVIDER,
  [USER_ROLES.VENDOR_SERVICE_PROVIDER]: ROUTER_PERMISSION.VENDOR_SERVICE_PROVIDER,
};
