import _map from 'lodash/map';
import _groupBy from 'lodash/groupBy';
import _maxBy from 'lodash/maxBy';
import { USER_ROLES, USER_ROLES_WEIGHT } from 'rapidfab/constants';
import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getPredicate, getStateResources } from 'rapidfab/selectors/helpers/base';
import { getSession } from 'rapidfab/selectors/session';

export const getRoles = createSelector(
  [baseStateSelectors.getStateRoles, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

/**
 * @deprecated consider to use Selectors.getUserRole()
 */
export const getUserRoles = createSelector(
  [getPredicate, getRoles],
  (user, roles) => {
    if (!user) {
      return [];
    }
    return roles.filter(
      role => role.username === (user.username ? user.username : user.email),
      // Check for username by default
      // but the username isn't stored on successful POST
      // so check the email as an alternative
    );
  },
);

export const getRolesGroupedByUsername = createSelector(
  [getRoles],
  roles => _groupBy(roles, 'username'),
);

export const getRolesCurrentUser = createSelector(
  [getRoles, getSession],
  (roles, session) => {
    if (!session) {
      return [];
    }
    return roles.filter(
      role => (role.username === session.username ? session : false),
    );
  },
);

export const getCurrentUserRole = createSelector(
  [getRoles, getSession],
  (roles, session) => {
    if (!session) {
      return null;
    }

    const allUserRoles = roles.filter(
      role => (role.username === session.username),
    );

    const maxUserRole = _maxBy(allUserRoles, userRole => USER_ROLES_WEIGHT[userRole.role]);

    if (!maxUserRole) {
      return null;
    }

    return maxUserRole.role;
  },
);

export const isCurrentUserRestricted = createSelector(
  [getRolesCurrentUser],
  roles => roles.length > 0 && roles.filter(role => role.role !== 'unknown').every(
    role => ['vendor-service-provider', 'service-provider', 'restricted', 'self-registered-guest'].includes(role.role),
  ),
);

export const isSessionManager = createSelector(
  [getSession, getRoles],
  (session, roles) => {
    if (!session) {
      return null;
    }
    return roles.some(
      role =>
        role.username === session.username &&
        role.role === 'manager',
    );
  },
);

export const isServiceProvider = createSelector(
  [getRolesCurrentUser],
  roles => roles.length > 0 && roles.every(role => role.role === 'service-provider'),
);

export const isVendorServiceProvider = createSelector(
  [getRolesCurrentUser],
  roles => roles.length > 0 && roles.some(role => role.role === USER_ROLES.VENDOR_SERVICE_PROVIDER),
);

export const isLocationManagerRole = createSelector(
  [getRolesCurrentUser],
  roles => roles.length > 0 && roles.some(role => role.role === USER_ROLES.LOCATION_MANAGER),
);

export const getCurrentUserRoleMax = createSelector(
  [getRolesCurrentUser],
  roles => _maxBy(roles, userRole => USER_ROLES_WEIGHT[userRole.role]),
);

export const getCurrentUserLocations = createSelector(
  [getRolesCurrentUser],
  roles => roles?.filter(role => role.role === USER_ROLES.LOCATION_USER) || [],
);

export const getCurrentLocationManagerLocations = createSelector(
  [getRolesCurrentUser],
  roles => roles?.filter(role => role.role === USER_ROLES.LOCATION_MANAGER) || [],
);

export const getSelectedUserRoles = createSelector(
  [getPredicate, getRoles],
  (user, roles) => {
    if (!user) {
      return [];
    }
    return roles.filter(
      role => role.username === (user.username ? user.username : user.email) && !role.location,
      // Check for username by default
      // but the username isn't stored on successful POST
      // so check the email as an alternative
    );
  },
);

export const getSelectedUserLocations = createSelector(
  [getPredicate, getRoles],
  (user, roles) => {
    if (!user) {
      return [];
    }
    return roles.filter(
      role => role.username === (user.username ? user.username : user.email) && role.role === USER_ROLES.LOCATION_USER,
      // Check for username by default
      // but the username isn't stored on successful POST
      // so check the email as an alternative
    );
  },
);

export const getSelectedUserLocationManagerLocations = createSelector(
  [getPredicate, getRoles],
  (user, roles) => {
    if (!user) {
      return [];
    }
    return roles.filter(
      role => role.username === (user.username ? user.username : user.email)
        && role.role === USER_ROLES.LOCATION_MANAGER,
      // Check for username by default
      // but the username isn't stored on successful POST
      // so check the email as an alternative
    );
  },
);

export const getSelectedUserRoleMax = createSelector(
  [getPredicate, getSelectedUserRoles],
  (user, roles) => _maxBy(roles, userRoles => USER_ROLES_WEIGHT[userRoles.role]),
);
