import React from 'react';
import { WORK_INSTRUCTION_UNITS_MAP, MATERIAL_UNITS_MAP } from 'rapidfab/mappings';
import PropTypes from 'prop-types';

const ALL_MAPPINGS = { ...WORK_INSTRUCTION_UNITS_MAP, ...MATERIAL_UNITS_MAP };

const FormattedWeight = ({ value, valueUnits }) => (
  <span>{value} {ALL_MAPPINGS[valueUnits]?.defaultMessage}</span>
);

export default FormattedWeight;

FormattedWeight.propTypes = {
  value: PropTypes.node.isRequired,
  valueUnits: PropTypes.string.isRequired,
};
