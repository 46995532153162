import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import PermanentContainerRecordForm from 'rapidfab/components/inventory/PermanentContainerRecordForm';
import Alert from 'rapidfab/utils/alert';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import React, { useEffect, useState } from 'react';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { FormattedMessage } from 'react-intl';
import {
  getBureauUri,
  getLocationsByRole,
  getMaterials,
  getSubLocations,
} from 'rapidfab/selectors';
import { useNavigate } from 'react-router-dom';

const PermanentContainerNewContainer = () => {
  const materials = useSelector(getMaterials);
  const locations = useSelector(getLocationsByRole);
  const subLocations = useSelector(getSubLocations);
  const bureau = useSelector(getBureauUri);
  const isSubmitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_CONTAINER].post.fetching);
  const subLocationsFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.SUB_LOCATION].list.fetching);
  const materialResourcesFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH].list.fetching);
  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);
  const usersByUri = useSelector(Selectors.getUsersByUri);

  const navigate = useNavigate();

  const initialFormValues = {};

  const [permanentContainerSelectedMaterials, setPermanentContainerSelectedMaterials] = useState([]);

  const [isAllMaterialsButtonSelected, setIsAllMaterialsButtonSelected] = useState(
    !!initialFormValues?.material_restrictions?.length
      && (initialFormValues?.material_restrictions?.length === materials?.length));

  const onSelectAllMaterials = (args, state) => {
    setPermanentContainerSelectedMaterials(materials);
    return state?.fields.material_restrictions.change(materials);
  };

  const onDeselectAllMaterials = (args, state) => {
    setPermanentContainerSelectedMaterials([]);
    return state?.fields.material_restrictions.change([]);
  };

  const selected = {
    initialFormValues,
    isSubmitting,
    materialData: {
      isAllMaterialsButtonSelected,
      setIsAllMaterialsButtonSelected,
      permanentContainerSelectedMaterials,
      materialResourcesFetching,
      materials,
      setPermanentContainerSelectedMaterials,
    },
    locationData: {
      locations,
      subLocations,
      subLocationsFetching,
    },
    isDebugModeEnabled,
    usersByUri,

  };

  const dispatch = useDispatch();

  const onInitialize = currentBureau => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: currentBureau }, {}, {}, {}, true));
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list());
  };
  const onSave = async newPermanentContainerPayload => {
    const payload = {
      ...newPermanentContainerPayload,
      material_restrictions: newPermanentContainerPayload?.material_restrictions || [],
    };

    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER]
      .post(payload))
      .then(permanentContainerResponse => {
        const permanentContainerUri = permanentContainerResponse?.headers?.location;

        if (permanentContainerUri) {
          Alert.success(<FormattedMessage
            id="toaster.permanentContainer.created"
            defaultMessage="Permanent container successfully created"
          />);

          return navigate(getRouteURI(ROUTES.PERMANENT_CONTAINER,
            { uuid: extractUuid(permanentContainerUri) },
            {}, true));
        }

        return Alert.error(<FormattedMessage
          id="toaster.permanentContainer.error_create"
          defaultMessage="Something went wrong while creating permanent container. Please try again."
        />);
      });
  };

  const loadSubLocations = async locationUri => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].clear('list'));
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list({ location: locationUri }));
  };

  useEffect(() => {
    onInitialize(bureau);
  }, [bureau]);

  const dispatched = {
    onSubmit: onSave,
    loadSubLocations,
    onSelectAllMaterials,
    onDeselectAllMaterials,
  };

  return (
    <Container fluid>
      <BreadcrumbNav
        breadcrumbs={['inventory', 'permanentContainers', 'New']}
      />

      <div className="page-header d-flex align-items-center">
        <h3 className="m-a-0">{initialFormValues?.name ?
          `Permanent Container: ${initialFormValues.name}` : (
            <FormattedMessage
              id="field.permanentContainer.new"
              defaultMessage="New Permanent Container"
            />
          )}
        </h3>
      </div>

      <Row>
        <Col xs={{ span: 12 }} md={{ span: 6 }}>
          <PermanentContainerRecordForm
            {...selected}
            {...dispatched}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default PermanentContainerNewContainer;
