import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import { COMMENT_RELATED_TABLE_NAMES_MAP } from 'rapidfab/mappings';
import {
  COMMENT_RELATED_TABLE_NAMES,
  NAVBAR_HEIGHT_PX,
  ROUTES,
} from 'rapidfab/constants';
import { FormattedDateTime } from 'rapidfab/i18n';
import MarkdownComponent from 'rapidfab/components/comments/Markdown';
import * as Selectors from 'rapidfab/selectors';
import { useSearchParams } from 'react-router-dom';
import { buildURIFromUUID, getRouteURI } from 'rapidfab/utils/uriUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CommentHeaderAction from './CommentHeaderAction';

const Comment = ({
  comment,
  showRelatedResource,
  showCommentActionAssignment,
  relatedResource,
  usersByUri,
  isAnythingFetching,
  labels,
}) => {
  const runsByUri = useSelector(Selectors.getRunsByUri);
  const ordersByUri = useSelector(Selectors.getOrdersByUri);

  const commentTooltipText = comment => {
    const RECORDS_BY_URI = {
      order: ordersByUri,
      run: runsByUri,
    };
    const uri = buildURIFromUUID(comment.related_table_name, comment.related_uuid);
    const uriRecord = RECORDS_BY_URI[comment.related_table_name][`${uri}/`];
    return uriRecord?.name;
  };

  const commentRef = useRef();
  const [searchParams] = useSearchParams();
  const getRelatedCommentResourceUri = ({
    related_table_name,
    related_uuid,
  }) => {
    switch (related_table_name) {
      case COMMENT_RELATED_TABLE_NAMES.ORDER:
        return getRouteURI(ROUTES.ORDER_EDIT, { uuid: related_uuid });
      case COMMENT_RELATED_TABLE_NAMES.RUN:
        return getRouteURI(ROUTES.RUN_EDIT, { uuid: related_uuid });
      default:
        return null;
    }
  };
  const linkToRelatedComment = getRelatedCommentResourceUri(comment);

  // We have to scroll to this comment once.
  // Always "false" if comment.uuid !== comment uuid from query params
  const [alreadyScrolledToTheComment, setAlreadyScrolledToTheComment] =
    useState(false);

  useEffect(() => {
    if (
      !alreadyScrolledToTheComment &&
      !isAnythingFetching &&
      commentRef?.current &&
      searchParams.get('comment') &&
      window.scrollY === 0
    ) {
      window.scrollTo(
        0,
        commentRef.current.getBoundingClientRect().top - NAVBAR_HEIGHT_PX,
        { behavior: 'smooth' },
      );
      setAlreadyScrolledToTheComment(true);
    }
  }, [
    commentRef,
    searchParams,
    isAnythingFetching,
    alreadyScrolledToTheComment,
  ]);

  // Potentially can be used for any related entity, but you have
  // make to sure that related resource is loaded
  const showRelatedResourceName =
    showRelatedResource &&
    comment.related_table_name === COMMENT_RELATED_TABLE_NAMES.PREP_TASK_RECORD;
  const relatedResourceName =
    showRelatedResourceName && relatedResource && relatedResource.name;

  // On POST action object may be exist already, but not fetched yet - uri is empty.
  // so "uri" check is required
  const isCommentActionExist = Boolean(_get(comment, 'comment_action.uri'));

  return (
    <div ref={commentRef} key={comment.uri} className="comment-item">
      <div className="d-flex flex-1 flex-direction-column">
        <div className="d-flex align-items-center">
          <div className="comment-item-name">{comment.author_name}</div>
          <div className="comment-item-date">
            {comment.created && <FormattedDateTime value={comment.created} />}
          </div>
          {showCommentActionAssignment && isCommentActionExist && (
            <CommentHeaderAction
              action={comment.comment_action}
              usersByUri={usersByUri}
            />
          )}
        </div>
        <MarkdownComponent
          source={comment.text}
          usersByUri={usersByUri}
          labels={labels}
        />
      </div>

      {showRelatedResource && (
        <div className="ml15 d-flex align-items-center">
          {showRelatedResourceName && 'On '}
          {linkToRelatedComment ? (

            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip>
                  {commentTooltipText(comment)}
                </Tooltip>
              )}
            >
              <a href={linkToRelatedComment}>
                <FormattedMessage
                  {...COMMENT_RELATED_TABLE_NAMES_MAP[comment.related_table_name]}
                />
                <FontAwesomeIcon className="spacer-left" icon={faExternalLink} />
              </a>
            </OverlayTrigger>
          ) : (
            <FormattedMessage
              {...COMMENT_RELATED_TABLE_NAMES_MAP[comment.related_table_name]}
            />
          )}
          {relatedResourceName && ` – "${relatedResourceName}"`}
        </div>
      )}
    </div>
  );
};

Comment.defaultProps = {
  showRelatedResource: false,
  showCommentActionAssignment: true,
  relatedResource: null,
  labels: [],
};

Comment.propTypes = {
  comment: PropTypes.shape({
    comment_action: PropTypes.shape({}),
    uri: PropTypes.string.isRequired,
    related_uuid: PropTypes.string.isRequired,
    author_name: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    related_table_name: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  usersByUri: PropTypes.shape({}).isRequired,
  isAnythingFetching: PropTypes.bool.isRequired,
  showRelatedResource: PropTypes.bool,
  showCommentActionAssignment: PropTypes.bool,
  relatedResource: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  labels: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = (state, ownProps) => ({
  relatedResource: state.resources[ownProps.comment.related_uuid],
  usersByUri: Selectors.getUsersByUri(state),
  isAnythingFetching: Selectors.isAnythingFetching(state),
  labels: Selectors.getLabels(state),
});

export default connect(mapStateToProps)(Comment);
