import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faColumns, faGripVertical, faCheckCircle, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Utility function to reorder an array based on drag and drop indices
const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [moved] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, moved);
  return result;
};

const ColumnVisibilityMenu = ({
  columns,
  visibleColumns,
  onToggleColumn,
  onReorderColumns,
  title = 'Customize Columns',
}) => {
  // State to control whether the dropdown is shown or hidden
  const [showDropdown, setShowDropdown] = useState(false);
  // State to keep track of the index of the column currently hovered over during drag
  const [hoveredIndex, setHoveredIndex] = useState(null);

  // Filter out any falsy values from the columns array (ensure only valid column objects are processed)
  const filteredColumns = columns.filter(Boolean);

  // Handle toggling the visibility of a column
  const handleToggle = accessor => {
    onToggleColumn(accessor);
  };

  // Handle the drag and drop result
  const onDragEnd = useCallback(
    result => {
      setHoveredIndex(null);
      // If there's no destination (e.g., dropped outside of list), do nothing
      if (!result.destination) return;
      // Reorder the columns based on the drag result
      const newOrder = reorder(
        filteredColumns,
        result.source.index,
        result.destination.index,
      );
      onReorderColumns(newOrder);
    },
    [filteredColumns, onReorderColumns],
  );

  // Update the hovered index state during drag to provide visual feedback
  const onDragUpdate = useCallback(update => {
    if (update.destination) {
      setHoveredIndex(update.destination.index);
    } else {
      setHoveredIndex(null);
    }
  }, []);

  return (
    <DropdownButton
      variant="primary"
      id="column-visibility-menu"
      title={(
        <>
          <FontAwesomeIcon icon={faColumns} className="spacer-right" />
          Layout
        </>
      )}
      show={showDropdown}
      onToggle={isOpen => setShowDropdown(isOpen)}
      className="customize-table-layout-button"
      data-bs-display="static"
      menuVariant="table-layout-dropdown-menu"
      renderMenuOnMount
      autoClose="outside"
      placement="bottom-start"
      popperConfig={{
        strategy: 'fixed',
        modifiers: [{ name: 'flip', enabled: false }],
      }}
    >
      <Dropdown.Header className="dropdown-menu-table-layout-dropdown-header">
        {title}
      </Dropdown.Header>
      <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
        <Droppable droppableId="columns-droppable">
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ padding: '4px 0', position: 'relative' }}
            >
              {filteredColumns.map((col, index) => {
                const isVisible = visibleColumns.includes(col.accessor);
                return (
                  <Draggable
                    key={col.accessor}
                    draggableId={col.accessor}
                    index={index}
                  >
                    {(dragProvided, dragSnapshot) => {
                      const isDragged = dragSnapshot.isDragging;
                      const isHovered = hoveredIndex === index && !isDragged;

                      return (
                        <div
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          {...dragProvided.dragHandleProps}
                          style={{
                            ...dragProvided.draggableProps.style,
                            userSelect: 'none',
                            cursor: 'grab',
                          }}
                          className={`table-layout-item draggable-column-item ${
                            isDragged ? 'draggable-dragging' : ''
                          } ${isHovered ? 'draggable-hovered' : ''}`}
                        >
                          <Dropdown.Item
                            as="div"
                            onClick={event => event.stopPropagation()} // Prevent dropdown from closing on item click
                            className="d-flex align-items-center justify-content-between"
                            style={{
                              padding: '8px 0',
                              background: 'transparent',
                            }}
                          >
                            <div className="d-flex align-items-center">
                              {/* Icon to indicate draggable area */}
                              <FontAwesomeIcon icon={faGripVertical} className="me-2 text-muted" />
                              <span className="dropdown-menu-table-layout-dropdown-menu-label">
                                {col.defaultMessage || col.uid}
                              </span>
                            </div>
                            <div
                              role="button"
                              tabIndex={0}
                              style={{ cursor: 'pointer' }}
                              onClick={event => {
                                // Toggle the visibility of the column without closing the dropdown
                                event.stopPropagation();
                                handleToggle(col.accessor);
                              }}
                              onKeyDown={event => {
                                // Handle keyboard interaction for toggling visibility
                                if (event.key === 'Enter' || event.key === ' ') {
                                  event.stopPropagation();
                                  handleToggle(col.accessor);
                                }
                              }}
                              aria-pressed={isVisible}
                            >
                              <FontAwesomeIcon
                                icon={isVisible ? faCheckCircle : faCircleMinus}
                                className={`font-size-16 text-${isVisible ? 'info' : 'secondary'}`}
                              />
                            </div>
                          </Dropdown.Item>
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </DropdownButton>
  );
};

ColumnVisibilityMenu.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string.isRequired,
      uid: PropTypes.string.isRequired,
      defaultMessage: PropTypes.string,
    }),
  ).isRequired,
  visibleColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleColumn: PropTypes.func.isRequired,
  onReorderColumns: PropTypes.func,
  title: PropTypes.string,
};

ColumnVisibilityMenu.defaultProps = {
  onReorderColumns: () => {},
  title: 'Customize Columns',
};

export default ColumnVisibilityMenu;
