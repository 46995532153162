import { useMemo } from 'react';
import { getBureauIntakeSettings, getPostProcessorTypesByUri, getPrinterTypesByUri, getShippingsByUri } from 'rapidfab/selectors';
import { useSelector } from 'react-redux';
import { API_RESOURCES } from 'rapidfab/constants';

const WORKSTEP_CHARGE_KEYS = {
  LABOR: 'labor',
  WORKSTATION: 'workstation',
  MATERIAL: 'material',
  OVERHEAD_PER_PIECE: 'overhead_per_piece',
  OVERHEAD_PER_RUN: 'overhead_per_run',
  ADDITIONAL: 'additional',
};

/** @typedef {Object} TableFields
  @property {string} key Same as key passed in
  @property {string} name Rendered name of row
  @property {number} total Total value, unused
  @property {string} unit - Name of unit to render
  @property {number} perUnit - Price per unit
  @property {number} count - Number of units
  */

/**
  * Calculates the count,
  * @param {keyof WORKSTEP_CHARGE_KEYS} key - Workstep Charge Key
  * @param {object} data - Collection of line item, quote, bureau settings and workstation type
  * @returns {TableFields}
  */
const calculateTableFieldsForRow = (key, data) => {
  const {
    quote,
    lineItem,
    bureauSettings,
    // The following variables are a union, only one is undefined
    printerType,
    postProcessorType,
    shipping,
  } = data;

  // Initialize fields
  let name = key;
  let total = 0;
  let perUnit = 0;
  let unit = 'Unit';

  switch (key) {
    case WORKSTEP_CHARGE_KEYS.LABOR:
      name = 'Labor';
      unit = 'Hour';
      total = quote.labor_price_per / lineItem.piece_count;
      perUnit = bureauSettings.default_labor_charge_per;
      break;
    case WORKSTEP_CHARGE_KEYS.MATERIAL:
      name = 'Material';
      total = quote.consumable_price_per / lineItem.piece_count;
      perUnit = total;
      break;
    case WORKSTEP_CHARGE_KEYS.OVERHEAD_PER_PIECE:
      name = 'Overhead (Per Piece)';
      total = (quote.overhead_cost_per_piece_in_run * quote.number_of_runs) / lineItem.piece_count;
      if (postProcessorType) {
        perUnit = postProcessorType.overhead_cost_per_piece_in_run;
      } else {
        perUnit = total;
      }
      break;
    case WORKSTEP_CHARGE_KEYS.OVERHEAD_PER_RUN:
      name = 'Overhead (Per Line Item)';
      total = quote.overhead_cost_per_run * quote.number_of_runs;
      if (postProcessorType) {
        perUnit = postProcessorType.overhead_cost_per_run * quote.number_of_runs;
      } else if (printerType) {
        perUnit = printerType.overhead_cost_per_run ?? quote.overhead_cost_per_run * quote.number_of_runs;
      } else {
        perUnit = total;
      }
      break;
    case WORKSTEP_CHARGE_KEYS.WORKSTATION: {
      unit = 'Hour';
      if (printerType?.running_cost_per_hour) {
        perUnit = printerType.running_cost_per_hour;
      } else if (shipping?.cost) {
        perUnit = shipping.cost;
      } else if (postProcessorType?.cost) {
        // Cost is per minute; multiply by 60 for per hour
        perUnit = postProcessorType.cost * 60;
      } else {
        perUnit = bureauSettings.default_workstation_charge_per;
      }
      name = 'Workstation Time';
      total = quote.workstation_price_per / lineItem.piece_count;
      break;
    }
    default: break;
  }

  if (perUnit === 0) {
    perUnit = 10;
  }

  return {
    key,
    name,
    total,
    unit,
    perUnit,
    count: (total / perUnit).toFixed(2),
  };
};

/**
  * Converts form data to the payload
  * @param {object} fields Form data
  * @param {number} pieceCount Number of pieces in the line item
  */
export const buildLineItemQuoteWorkstepPayload = (fields, pieceCount) =>
  // eslint-disable-next-line unicorn/no-array-reduce
  fields.reduce((_payload, field) => {
    // Reassign to mutate
    const payload = _payload;
    // Fields in the form are displayed as counts, per-piece. Convert them back to totals
    const defaultValue = field.perUnit * Number.parseFloat(field.count) * pieceCount;
    switch (field.key) {
      case WORKSTEP_CHARGE_KEYS.LABOR:
        payload.labor_price_per = defaultValue;
        break;
      case WORKSTEP_CHARGE_KEYS.MATERIAL:
        payload.consumable_price_per = defaultValue;
        break;
      case WORKSTEP_CHARGE_KEYS.OVERHEAD_PER_PIECE:
        payload.overhead_cost_per_piece_in_run = defaultValue;
        break;
      case WORKSTEP_CHARGE_KEYS.OVERHEAD_PER_RUN:
        payload.overhead_cost_per_run = defaultValue / pieceCount;
        break;
      case WORKSTEP_CHARGE_KEYS.WORKSTATION:
        payload.workstation_price_per = defaultValue;
        break;
      case WORKSTEP_CHARGE_KEYS.ADDITIONAL:
        payload.additional_charges.push({
          charge_name: field.name,
          charge_item_units: field.unit,
          unit_count: Number.parseFloat(field.count),
          price_per_unit: field.perUnit,
        });
        break;
      default: break;
    }
    return payload;
  }, { additional_charges: [] });

const PRINTING_COSTS = [
  WORKSTEP_CHARGE_KEYS.LABOR,
  WORKSTEP_CHARGE_KEYS.WORKSTATION,
  WORKSTEP_CHARGE_KEYS.MATERIAL,
  WORKSTEP_CHARGE_KEYS.OVERHEAD_PER_PIECE,
  WORKSTEP_CHARGE_KEYS.OVERHEAD_PER_RUN,
];

const POST_PROCESSING_COSTS = [
  WORKSTEP_CHARGE_KEYS.LABOR,
  WORKSTEP_CHARGE_KEYS.WORKSTATION,
  WORKSTEP_CHARGE_KEYS.OVERHEAD_PER_PIECE,
  WORKSTEP_CHARGE_KEYS.OVERHEAD_PER_RUN,
];

const SHIPPING_COSTS = [
  WORKSTEP_CHARGE_KEYS.LABOR,
  WORKSTEP_CHARGE_KEYS.WORKSTATION,
];

/**
  * Returns the type of the process step by looking at the resource of the workstation type uri
  * @param {string} uri
  * @returns {"post-processor-type" | "printer-type" | "shipping" | undefined}
  */
const getProcessStepType = uri => {
  const types = [API_RESOURCES.POST_PROCESSOR_TYPE, API_RESOURCES.PRINTER_TYPE, API_RESOURCES.SHIPPING];
  // eslint-disable-next-line no-restricted-syntax
  for (const type of types) {
    if (uri.includes(type)) {
      return type;
    }
  }
  // eslint-disable-next-line unicorn/no-useless-undefined
  return undefined;
};

/**
  * Returns the charges that should be considered for a given process step
  * @param {string} uri of the process step's workstation type
  * @returns {keyof WORKSTEP_CHARGE_KEYS[]}
  */
const getWorkstepChargeKeys = uri => {
  const processStepType = getProcessStepType(uri);
  switch (processStepType) {
    case (API_RESOURCES.POST_PROCESSOR_TYPE): return POST_PROCESSING_COSTS;
    case (API_RESOURCES.PRINTER_TYPE): return PRINTING_COSTS;
    case (API_RESOURCES.SHIPPING): return SHIPPING_COSTS;
    // eslint-disable-next-line unicorn/no-useless-undefined
    default: return undefined;
  }
};

/**
  * Takes a line item and selected workstep, and returns initial data to be rendered in the Line Item Quote table
  *
  * @param {object} lineItem - Selected line item
  * @param {object} workstep - Selected workstep
  */
export const useWorkstepTableData = (lineItem, workstep) => {
  const bureauSettings = useSelector(getBureauIntakeSettings);
  // These will be a union; only one not undefined
  const printerType = useSelector(getPrinterTypesByUri)[workstep.workstation_type_uri];
  const postProcessorType = useSelector(getPostProcessorTypesByUri)[workstep.workstation_type_uri];
  const shipping = useSelector(getShippingsByUri)[workstep.workstation_type_uri];

  const rows = getWorkstepChargeKeys(workstep.workstation_type_uri);

  const quote = workstep.work_steps_quote_details;

  const rowsData = useMemo(() => (rows.map(row => calculateTableFieldsForRow(row, {
    lineItem,
    quote,
    bureauSettings,
    printerType,
    postProcessorType,
    shipping,
  }))), [
    rows,
    lineItem,
    quote,
    bureauSettings,
    printerType,
    postProcessorType,
    shipping,
  ]);

  const tableData = [...rowsData, ...quote.additional_charges.map(charge => ({
    key: WORKSTEP_CHARGE_KEYS.ADDITIONAL,
    name: charge.charge_name,
    total: charge.price_per_unit * charge.unit_count,
    perUnit: charge.price_per_unit,
    count: charge.unit_count,
    unit: 'Unit',
  }))];

  // Calculate total price
  // eslint-disable-next-line unicorn/no-array-reduce
  const total = tableData.reduce((accumulator, current) => {
    if (current.key === WORKSTEP_CHARGE_KEYS.OVERHEAD_PER_RUN) {
      return accumulator + current.total;
    }
    return accumulator + (current.total * lineItem.piece_count);
  }, 0);

  return { data: tableData, total };
};
