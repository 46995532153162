import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Canvas } from '@react-three/fiber';
// Direct imports required due to dependency mismatch between @react-three/drei and three
import { Center } from '@react-three/drei/core/Center';
import { OrbitControls } from '@react-three/drei/core/OrbitControls';
import { useSelector } from 'react-redux';
import { getAddiguruAnomaliesByAnalysis } from 'rapidfab/selectors/addiguru';
import { API_RESOURCES } from 'rapidfab/constants';
import Loading from 'rapidfab/components/Loading';

const PointCloud = ({ analysisUri }) => {
  const anomalies = useSelector(state => getAddiguruAnomaliesByAnalysis(state, analysisUri));
  const isLoading = useSelector(state => state.ui.nautilus[API_RESOURCES.ADDIGURU_ANOMALY].list.fetching);

  const points = useMemo(() => (
    new Float32Array(anomalies.flatMap(anomaly => {
      const coords = JSON.parse(anomaly.boundary);
      // Contours have form [[[x, y]]]
      if (anomaly.shape === 'contour') {
        return coords.flatMap(row => row.flatMap(coord => ([coord[0] / 50, coord[1] / 50, anomaly.layer_number / 4])));
      }
      // Rects have form [[x, y]]
      return coords.flatMap(coord => ([coord[0] / 50, coord[1] / 50, anomaly.layer_number / 4]));
    }))
  ), [anomalies]);

  if (isLoading) {
    return <Loading />;
  }

  if (!points.length) {
    return (
      <div className="d-flex w-100 h-100 align-items-center text-center p-4">
        <span><em>No Anomalies detected in this Analysis</em></span>
      </div>
    );
  }

  /* eslint-disable react/no-unknown-property */
  return (
    <Canvas
      camera={{
        fov: 45,
        near: 0.1,
        far: Number.MAX_SAFE_INTEGER,
        position: [20, 20, 20],
      }}
    >

      <OrbitControls />
      <ambientLight intensity={0.1 * Math.PI} />
      <directionalLight intensity={3} position={[20, 20, 20]} />
      <gridHelper rotation-x={Math.PI / 2} position-z={-2} args={[20, 20, 0x434857, 0x434857]} />

      <Center>
        {points.length && (
          <points>
            <bufferGeometry attach="geometry">
              <bufferAttribute
                attachObject={['attributes', 'position']}
                count={points.length / 3}
                array={points}
                itemSize={3}
              />
            </bufferGeometry>
            <pointsMaterial sizeAttenuation={false} attach="material" color="white" depthWrite={false} size={3} />
          </points>
        )}
      </Center>
    </Canvas>
  );
  /* eslint-disable react/no-unknown-property */
};

PointCloud.propTypes = {
  analysisUri: PropTypes.string.isRequired,
};

export default PointCloud;
