import React from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Row } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import 'rapidfab/styles/componentStyles/custom-darken-modal.scss';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';
import { API_RESOURCES } from 'rapidfab/constants';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { getAddiguruInstallationByUuid } from 'rapidfab/selectors/addiguru';
import Alert from 'rapidfab/utils/alert';
import FormGroupField from '../forms/FormGroupField';

const InstallationModal = ({ isModalOpen, closeAddiguruInstallationModal }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');
  const installation = useSelector(state => getAddiguruInstallationByUuid(state, uuid));

  const dispatch = useDispatch();

  const onSubmit = async values => {
    if (uuid) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.ADDIGURU_INSTALLATION].put(uuid, {
        ...values,
        password: values.password === '' ? values.password : undefined,
      }));
      searchParams.delete('uuid');
      setSearchParams(searchParams);
    } else {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.ADDIGURU_INSTALLATION].post({
        ...values,
      }));
    }
    await dispatch(Actions.Api.nautilus[API_RESOURCES.ADDIGURU_INSTALLATION].list());
    closeAddiguruInstallationModal();
  };

  const onDelete = async () => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.ADDIGURU_INSTALLATION].delete(uuid));
    closeAddiguruInstallationModal();
    Alert.success('Installation successfully deleted.');
    await dispatch(Actions.Api.nautilus[API_RESOURCES.ADDIGURU_INSTALLATION].list());
  };

  return (
    <Modal
      bg="dark"
      show={uuid || isModalOpen}
      scrollable
      backdrop="static"
      dialogClassName="custom-darken-modal"
    >
      <Form
        onSubmit={onSubmit}
        initialValues={installation ? {
          name: installation.name,
          username: installation.username,
          ip_address: installation.ip_address,
        } : {}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Modal.Header>
              <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  Addiguru Installation
                </div>
                <FontAwesomeIcon
                  icon={faClose}
                  onClick={closeAddiguruInstallationModal}
                  tabIndex={0}
                  role="button"
                  className="custom-darken-modal-button"
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-darken-modal--body-scroll">
              <Row className="custom-darken-modal-custom-gutter">
                <Field
                  name="name"
                  type="text"
                  render={props => (
                    <FormGroupField
                      {...props.input}
                      as="input"
                      name="name"
                      controlId="uxName"
                      label={(<span>Name</span>
                      )}
                      required
                      placeholder="Name"
                    />
                  )}
                />
              </Row>
              <Row className="custom-darken-modal-custom-gutter">
                <Field
                  name="ip_address"
                  type="text"
                  render={props => (
                    <FormGroupField
                      {...props.input}
                      as="input"
                      name="ip_ddress"
                      controlId="uxIpAddress"
                      label={(<span>Installation Address (IP Address + Port or URL)</span>
                      )}
                      required
                      placeholder="e.g 127.0.0.1:3000"
                    />
                  )}
                />
              </Row>
              <Row className="custom-darken-modal-custom-gutter">
                <Field
                  name="username"
                  type="text"
                  render={props => (
                    <FormGroupField
                      {...props.input}
                      as="input"
                      name="username"
                      controlId="uxUsername"
                      label={(<span>API Login</span>
                      )}
                      required
                      placeholder="Username"
                      autocomplete="off"
                    />
                  )}
                />
              </Row>
              <Row className="custom-darken-modal-custom-gutter">
                <Field
                  name="password"
                  type="password"
                  render={props => (
                    <FormGroupField
                      {...props.input}
                      as="input"
                      name="password"
                      controlId="uxPassword"
                      required={!uuid}
                      label={(<span>API Password</span>
                      )}
                      placeholder="Password"
                      autocomplete="new-password"
                    />
                  )}
                />
              </Row>
            </Modal.Body>
            <Modal.Footer>
              {uuid && (
                <Button type="button" variant="danger" onClick={onDelete}>
                  Delete
                </Button>
              )}
              <Button type="submit" variant="success">
                {uuid ? 'Save' : 'Create'}
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
};

InstallationModal.propTypes = {
  input: PropTypes.shape({}).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  closeAddiguruInstallationModal: PropTypes.func.isRequired,
};

export default InstallationModal;
