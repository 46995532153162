import React, { useState } from 'react';
import { Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormattedDateTime } from 'rapidfab/i18n';
import AddiguruAnalysisStatus from './AddiguruAnalysisStatus';
import UnlinkAnalysisDialog from './UnlinkAnalysisDialog';

const AddiguruAnalysisTable = ({ analysis }) => {
  const [showUnlinkDialog, setShowUnlinkDialog] = useState(false);
  return (
    <div style={{ paddingRight: 15 }}>
      <div className="d-flex pb-1 flex-row justify-content-between align-items-end">
        <h4><strong>Analysis</strong></h4>
        <div className="d-flex gap-2">
          <Button size="sm" variant="danger" onClick={() => setShowUnlinkDialog(true)}>
            Unlink
          </Button>
        </div>
        <UnlinkAnalysisDialog analysisUuid={analysis.uuid} show={showUnlinkDialog} setShow={setShowUnlinkDialog} />
      </div>

      <ListGroup>
        <ListGroupItem>
          <div className="pull-left d-flex flex-row gap-2">
            <strong>
              Id
            </strong>
          </div>
          <div className="pull-right d-flex flex-row gap-2">
            {analysis.addiguru_id}
          </div>
        </ListGroupItem>
        <ListGroupItem>
          <div className="pull-left d-flex flex-row gap-2">
            <strong>
              Status
            </strong>
          </div>
          <div className="pull-right d-flex flex-row gap-2">
            <AddiguruAnalysisStatus status={analysis.status} />
          </div>
        </ListGroupItem>
        <ListGroupItem>
          <div className="pull-left d-flex flex-row gap-2">
            <strong>
              Layers
            </strong>
          </div>
          <div className="pull-right d-flex flex-row gap-2">
            {analysis.layer_count}
          </div>
        </ListGroupItem>
        <ListGroupItem>
          <div className="pull-left d-flex flex-row gap-2">
            <strong>
              Start
            </strong>
          </div>
          <div className="pull-right d-flex flex-row gap-2">
            <FormattedDateTime value={analysis.start_time} />
          </div>
        </ListGroupItem>
        <ListGroupItem>
          <div className="pull-left d-flex flex-row gap-2">
            <strong>
              End
            </strong>
          </div>
          <div className="pull-right d-flex flex-row gap-2">
            <FormattedDateTime value={analysis.end_time} />
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>
  );
};

AddiguruAnalysisTable.propTypes = {
  analysis: PropTypes.shape({
    addiguru_id: PropTypes.string,
    status: PropTypes.string,
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    anomalies: PropTypes.number,
    defects: PropTypes.number,
    total_anomalies: PropTypes.number,
    uuid: PropTypes.string,
    layer_count: PropTypes.number,
  }).isRequired,
};

export default AddiguruAnalysisTable;
