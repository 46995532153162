import _maxBy from 'lodash/maxBy';
import { USER_ROLES, USER_ROLES_WEIGHT } from 'rapidfab/constants';
import { getRolesCurrentUser } from 'rapidfab/selectors/helpers/roles';
import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _keyBy from 'lodash/keyBy';
import { getPermissions } from 'rapidfab/selectors/permissions';

export const getLocations = createSelector(
  [baseStateSelectors.getStateLocations, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getLocationsByRole = createSelector(
  [getLocations, getRolesCurrentUser],
  (locations, roles) => {
    // Get the highest role of teh current user
    const userRoleMax = _maxBy(roles, userRoles => USER_ROLES_WEIGHT[userRoles.role]);
    // Decide which locations to show based on the user's role
    let locationsFiltered = locations;
    // If the user highest role is manager -> no restrictions, otherwise:
    if (userRoleMax?.role !== USER_ROLES.MANAGER) {
      // The user is not a manager so we should only show the locations they have access to
      // (get all location roles uris)
      const allowedLocations = new Set(roles.map(role => role.location).filter(Boolean));
      // Filter locations by allowed locations
      locationsFiltered = locations.filter(location => allowedLocations.has(location.uri));
    }

    // Return filtered locations
    return locationsFiltered;
  },
);

export const getLocationsByUri = createSelector([getLocations], locations =>
  _keyBy(locations, 'uri'),
);

export const getPermissionLocationUris = createSelector(
  [getPermissions],
  permissions => _map(_filter(permissions, { right: 'printer.all' }), permission => permission.object),
);
