import PropTypes from 'prop-types';
import ToolingStockCreateModal from 'rapidfab/components/inventory/ToolingStockCreateModal';
import React, { useEffect, useState } from 'react';
import Actions from 'rapidfab/actions';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import Alert from 'rapidfab/utils/alert';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Selectors from 'rapidfab/selectors';
import { TOOLING_STOCK_FORM } from 'rapidfab/constants/forms';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { FormattedMessage } from 'react-intl';
import { getToolingStockTypes } from 'rapidfab/selectors/toolingStockType';

const ToolingStockCreateContainer = props => {
  const locations = useSelector(Selectors.getLocationsByRole);
  // Selected location in form to fetch sub locations
  const [location, setLocation] = useState();
  const subLocations = useSelector(state =>
    Selectors.getSubLocationsForLocation(state, location),
  );

  const toolingStockTypes = useSelector(getToolingStockTypes);

  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list());
  }, [dispatch]);

  const onSubmit = async formValues => {
    setSubmitting(true);
    const payload = { ...formValues };

    TOOLING_STOCK_FORM.NULL_FIELDS.forEach(fieldName => {
      const field = _get(payload, fieldName);
      if (field === '') {
        _set(payload, fieldName, null);
      }
    });
    try {
      const response = await dispatch(
        Actions.Api.nautilus[API_RESOURCES.TOOLING_STOCK].post(payload),
      );

      // BE will return toolingStock URI as a part of the response headers (location)
      // TODO STOCK
      const { uri } = response.payload;

      Alert.success(
        <FormattedMessage
          id="toaster.tooling_stock.created"
          defaultMessage="Tool successfully created."
        />,
      );
      if (uri) {
        navigate(
          getRouteURI(
            ROUTES.TOOLING_STOCK_DETAIL,
            { uuid: extractUuid(uri) },
            null,
            true,
          ),
          { replace: true },
        );
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ToolingStockCreateModal
      {...props}
      locations={locations}
      subLocations={subLocations}
      toolingStockTypes={toolingStockTypes}
      onSubmit={onSubmit}
      show={props.show}
      handleCloseModal={props.hideModal}
      submitting={submitting}
      onLocationChange={newLocation => setLocation(newLocation)}
    />
  );
};

ToolingStockCreateContainer.propTypes = {
  show: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default ToolingStockCreateContainer;
