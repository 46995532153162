import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import { Link } from 'react-router-dom';

/** Parses a string with a given Regex and interleaves it with <Link> components
  * Warning: Be very careful with the regex here. Use only if necessary
  *
  * @param {object} props
  * @param {string} props.string The string to parse and render
  * @param {string} props.pattern The regex pattern to test
  * @param {string} props.linkName The text to render next to the short UUID of the parsed link
  *
  * @returns React fragment of spans and Links
*/
export const LinkRenderer = ({ string, pattern, linkName }) => {
  const re = new RegExp(pattern);

  const parts = useMemo(() => ([...string.split(re)]), [string, pattern]);

  // Checks if a part matches the regex provided. If so, attempts to parse the short UUID and
  // returns a <Link> component. Otherwise returns a span.
  const renderPart = part => {
    if (re.test(part)) {
      // We only have the URL path, so extractUUID wouldn't work here
      // # / records / resource / uuid <- indexed at 3
      const shortUuid = getShortUUID(part.split('/').slice(3, 4));
      if (shortUuid) {
        // Slice drops the `#` so hash linking works
        return <Link to={part.slice(1)} target="_blank">{linkName} {shortUuid}</Link>;
      }
    }
    // TODO: class, styles, props
    return <span>{part}</span>;
  };

  return (
    <>
      {parts.map(part => renderPart(part))}
    </>
  );
};

LinkRenderer.propTypes = {
  string: PropTypes.string.isRequired,
  pattern: PropTypes.string.isRequired,
  linkName: PropTypes.string.isRequired,
};

/** Parses a string and returns same string as a React fragment, with Run record URL paths rendered as Links.
  * This _only_ looks for URL paths of the form `#/records/run/...`
*/
export const RunLinkRenderer = ({ string }) => (
  <LinkRenderer
    string={string}
    pattern={/(#\/records\/run\/[^\s.]*)/} // Accepts any non-whitespace or non-period chars after the resource
    linkName="Run"
  />
);

RunLinkRenderer.propTypes = {
  string: PropTypes.string.isRequired,
};
