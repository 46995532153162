import { isLocationManagerRole } from 'rapidfab/selectors';
import { FormattedMessage } from 'react-intl';
import Alert from 'rapidfab/utils/alert-new';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Selectors from 'rapidfab/selectors';
import { API_RESOURCES, FEATURES, NON_ENTITY_API_RESOURCES, USER_MODAL_MODES } from 'rapidfab/constants';

import { AdminUsers } from 'rapidfab/components/admin';
import Actions from 'rapidfab/actions';
import { useSearchParams } from 'react-router-dom';

const AdminUsersContainer = () => {
  const isSessionManager = useSelector(Selectors.isSessionManager);
  const isLocationManager = useSelector(isLocationManagerRole);
  const isRestrictedUser = useSelector(Selectors.isCurrentUserRestricted);
  const locations = useSelector(Selectors.getLocations);
  const locationsByUri = useSelector(Selectors.getLocationsByUri);
  const isDeletingUser = useSelector(state => state.ui.nautilus[API_RESOURCES.USERS].delete.fetching);
  const isSelfRegistrationAllowedFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.SELF_REGISTRATION_ALLOWED));
  const dispatch = useDispatch();
  const isPOCUKOrderFieldsFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.POC_UK_ORDER_FIELDS));

  const [filter, setFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [csrfToken, setCsrfToken] = useState(null);

  const [searchParams] = useSearchParams();
  const isCreateNewUserMode = searchParams.get('isCreateNewUser');

  const [userModalMode, setUserModalMode] = useState(null);
  const [currentUserPreview, setCurrentUserPreview] = useState(false);
  const [deleteUserWarning, setDeleteUserWarning] = useState(false);

  const [newUserName, setNewUserName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');

  useEffect(() => {
    // Update controlled values based on the current user preview
    setNewUserName(currentUserPreview?.name || currentUserPreview?.username);
    setNewUserEmail(currentUserPreview?.username);
  }, [currentUserPreview]);

  const handleNewUserInputs = useCallback(event => {
    const { name, value } = event.target;
    const normalized = value.trim();
    if (name === 'name') {
      setNewUserName(value);
    } else if (name === 'email') {
      setNewUserEmail(normalized);
    }
  }, []);

  const handleFilterChange = value => {
    setFilter(value);
  };

  const handleRoleFilterChange = value => {
    setRoleFilter(value);
  };

  const handleCloseModal = useCallback(() => {
    setUserModalMode(null);
    setCurrentUserPreview(null);
    setNewUserName('');
    setNewUserEmail('');
  }, []);

  const handleDeleteUser = async user => {
    try {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].delete(user.uuid));
      Alert.success(<FormattedMessage id="user.deleted" defaultMessage="User has successfully been deleted" />);
      handleCloseModal();
    } catch {
      Alert.error(<FormattedMessage id="user.deleteError" defaultMessage="Failed to delete the user. Please try again." />);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const tokens = await dispatch(Actions.Api.nautilus[NON_ENTITY_API_RESOURCES.CSRF_TOKEN].list());
      if (tokens?.json?.resources?.[0]) {
        setCsrfToken(tokens.json.resources[0].token);
      }
    };

    if (isCreateNewUserMode) {
      setUserModalMode(USER_MODAL_MODES.ADD);
    }

    fetch();
  }, []);

  return (
    <AdminUsers
      filter={filter}
      handleFilterChange={handleFilterChange}
      roleFilter={roleFilter}
      handleRoleFilterChange={handleRoleFilterChange}
      locations={locations}
      isSessionManager={isSessionManager}
      isRestrictedUser={isRestrictedUser}
      isSelfRegistrationAllowedFeatureEnabled={isSelfRegistrationAllowedFeatureEnabled}
      csrfToken={csrfToken}
      handleDeleteUser={handleDeleteUser}
      handleCloseModal={handleCloseModal}
      isLocationManager={isLocationManager}
      locationsByUri={locationsByUri}
      newUserInputProps={{
        handleNewUserInputs,
        newUserName,
        newUserEmail,
        deleteUserWarning,
        setDeleteUserWarning,
        isDeletingUser,
      }}
      userModalModeProps={{
        setUserModalMode,
        userModalMode,
        currentUserPreview,
        setCurrentUserPreview,
      }}
      isPOCUKOrderFieldsFeatureEnabled={isPOCUKOrderFieldsFeatureEnabled}
    />
  );
};

export default AdminUsersContainer;
