import PropTypes from 'prop-types';
import { loadAdditionalRunResourcesByRunUris } from 'rapidfab/dispatchers/run';
import usePagination from 'rapidfab/hooks/usePagination';
import {
  findCurrentRunStartDate,
  findScheduledRunsForResource, getRunProgressDetails,
  isRunActive,
} from 'rapidfab/utils/getRunName';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';

import PrintersComponent from 'rapidfab/components/assets/printers';
import {
  API_RESOURCES,
  FEATURES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  VIEW_MODE_OPTIONS,
} from 'rapidfab/constants';
import {
  getLocationFilter,
  getMaterialBatches, getRunActualsKeyedByRunUri,
  getRunEstimatesByRunUri, getSubLocationFilter,
} from 'rapidfab/selectors';
import _flatMap from 'lodash/flatMap';
import usePrevious from 'rapidfab/hooks';

const PrintersContainer = memo(props => {
  const [viewMode, setViewMode] = useState(props.viewMode || VIEW_MODE_OPTIONS.CARDS);
  const isCardsView = viewMode === VIEW_MODE_OPTIONS.CARDS;
  const [buildsFetched, setBuildsFetched] = useState(false);
  const [additionalResourcesFetched, setAdditionalResourcesFetched] = useState(false);
  const [filterValue, setFilterValue] = useState('');

  const fetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.LOCATION].list.fetching ||
    state.ui.nautilus[API_RESOURCES.PRINTER].list.fetching ||
    state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].list.fetching);

  const modelersFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MODELER].list.fetching);
  const batchesFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH].list.fetching);
  const buildsFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.BUILD].list.fetching);
  const runsFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.SCHEDULE_RUNS].list.fetching ||
    state.ui.nautilus[API_RESOURCES.RUN_ACTUALS].list.fetching ||
    state.ui.nautilus[API_RESOURCES.RUN].list.fetching);

  const locations = useSelector(Selectors.getLocationsByUri);
  const subLocationsByUri = useSelector(Selectors.getSubLocationsByUri);
  const modelers = useSelector(Selectors.getModelersByUri);
  const builds = useSelector(Selectors.getBuildsByUri);
  const printers = useSelector(Selectors.getPrinters);
  const printerTypes = useSelector(Selectors.getPrinterTypesByUri);
  const batches = useSelector(getMaterialBatches);
  const runs = useSelector(Selectors.getRunsByPrinterUri);
  const runEstimatesKeyedByRunUri = useSelector(getRunEstimatesByRunUri);
  const runActuals = useSelector(getRunActualsKeyedByRunUri);

  const locationFilter = useSelector(getLocationFilter);
  const subLocationFilter = useSelector(getSubLocationFilter);

  const isGroupQualificationsFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.GROUP_QUALIFICATIONS));
  const isGeneralMFGLanguageEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.GENERAL_MFG_LANGUAGE));
  const isMaterialManagementFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.MATERIAL_MANAGEMENT));

  const groupedPrinters = useSelector(Selectors.getPrintersGroupedByLocationAndSubLocation);

  const {
    paginationState,
    setTotalItems,
    setPage,
    nextPage,
    prevPage,
    totalPaginatedPages,
    resetOffset,
  } = usePagination(15);

  const [globalFilter, setGlobalFilter] = useState('');

  const selected = useMemo(() => ({
    fetching,
    locations,
    modelers,
    printers,
    printerTypes,
    isGroupQualificationsFeatureEnabled,
    isGeneralMFGLanguageEnabled,
    batches,
    isMaterialManagementFeatureEnabled,
    batchesFetching,
    groupedPrinters,
    viewMode,
    setViewMode,
    isCardsView,
    buildsFetching,
    builds,
    runsFetching,
    subLocationsByUri,
    modelersFetching,
  }), [
    batches,
    batchesFetching,
    builds,
    buildsFetching,
    fetching,
    groupedPrinters,
    isCardsView,
    isGeneralMFGLanguageEnabled,
    isGroupQualificationsFeatureEnabled,
    isMaterialManagementFeatureEnabled,
    locations,
    modelers,
    printerTypes,
    printers,
    runsFetching,
    subLocationsByUri,
    viewMode,
    modelersFetching,
  ]);

  const dispatch = useDispatch();

  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list());
  };

  const loadAdditionalResources = printers => {
    const printerUris = _flatMap(printers, 'uri');

    if (isMaterialManagementFeatureEnabled && printerUris.length) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH].list({ at_machine: printerUris }));
    }

    const runUris = _flatMap(printers, 'queue');
    loadAdditionalRunResourcesByRunUris(runUris, dispatch);
  };

  const loadPrinters = () => {
    const findBy = {
      ...(locationFilter && { location: locationFilter }),
      ...(subLocationFilter && { sub_location: subLocationFilter }),
    };
    const filter = {
      sort: 'name',
    };

    if (globalFilter) {
      filter.multicolumn_search = globalFilter;
    }

    // Clear all the resources if we are going through the pagination
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].clear('list'));
    dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].clear('list'));
    dispatch(Actions.Api.nautilus[API_RESOURCES.SCHEDULE_RUNS].clear('list'));

    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].list({ ...findBy },
      { limit: paginationState.pageLimit,
        offset: paginationState.offset > 0 ? paginationState.offset : 0 }, {}, { ...filter }, true))
      .then(printersResponse => {
        setTotalItems(printersResponse.json?.meta?.count);

        const printers = printersResponse.json?.resources;

        // Load additional resources only if we are in the cards view
        if (printers?.length && isCardsView) {
          loadAdditionalResources(printers);
          setAdditionalResourcesFetched(true);
          // Set "false" only if we had resources, to prevent additional API calls.
        } else if (printers.length) {
          // Reset if we are not in the cards view
          setAdditionalResourcesFetched(false);
        }
      });
  };

  const previousPage = usePrevious(paginationState.activePage);
  const previousLocationFilter = usePrevious(locationFilter);
  const previousSubLocationFilter = usePrevious(subLocationFilter);
  const previousGlobalFilter = usePrevious(globalFilter);

  useEffect(() => {
    /*
      We need to send API calls only if:
      1. Initial loading in the LIST view, sending the base Printer API calls
         without additional resources.
      2. Switching between the LIST and CARDS view but only ONCE, to prevent
         sending the same API calls multiple times.
      3. Pagination changes: In LIST view only Printer's data, in CARD view
         also all the additional resources.
      4. Global filter changes.
      5. There should not be any duplicated API calls.
    */

    if ((!additionalResourcesFetched && isCardsView)
      || previousPage !== paginationState.activePage
      || globalFilter !== previousGlobalFilter
      || (previousLocationFilter !== locationFilter
      || previousSubLocationFilter !== subLocationFilter)) {
      loadPrinters();
    }
  }, [
    paginationState.pageLimit,
    paginationState.offset,
    paginationState.activePage,
    locationFilter,
    subLocationFilter,
    globalFilter,
    isCardsView,
    additionalResourcesFetched,
  ]);

  useEffect(() => onInitialize(), []);

  useEffect(() => {
    if (isCardsView && !buildsFetched) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD].list({
        ...(Object.keys(modelers).length && { modeler: Object.keys(modelers) }),
      }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
      setBuildsFetched(true);
    }
  }, [buildsFetched, dispatch, isCardsView, JSON.stringify(Object.keys(modelers))]);

  const findScheduledRuns = useCallback(printer =>
    findScheduledRunsForResource(printer, runs, runEstimatesKeyedByRunUri), [runEstimatesKeyedByRunUri, runs]);

  const getCurrentPercentageOfTheCurrentScheduledRun = useCallback(run =>
    getRunProgressDetails(run, runEstimatesKeyedByRunUri, runActuals),
  [runEstimatesKeyedByRunUri]);

  const findRunStartDate = useCallback(run => {
    findCurrentRunStartDate(run, runEstimatesKeyedByRunUri);
  }, [runEstimatesKeyedByRunUri]);

  const dispatched = {
    findScheduledRuns,
    getPercentage: getCurrentPercentageOfTheCurrentScheduledRun,
    scheduledStartDate: findRunStartDate,
    isRunActive,
  };

  return (
    <PrintersComponent
      {...props}
      {...selected}
      {...dispatched}
      filters={{
        globalFilter,
        setGlobalFilter,
      }}
      filterValues={{
        filterValue,
        setFilterValue,
      }}
      pagination={{
        ...paginationState,
        nextPage,
        prevPage,
        setPage,
        totalPaginatedPages,
        resetOffset,
      }}
    />
  );
});

PrintersContainer.propTypes = {
  viewMode: PropTypes.string,
};

PrintersContainer.defaultProps = {
  viewMode: VIEW_MODE_OPTIONS.CARDS,
};

export default PrintersContainer;
