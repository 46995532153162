import BureauBarcodeIcon from 'rapidfab/components/BureauBarcodeIcon';
import ColumnVisibilityMenu from 'rapidfab/components/Tables/ColumnVisibilityToggler';
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
// noinspection ES6CheckImport
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce, useResizeColumns, useFlexLayout } from 'react-table';
import { Col, Button, FormLabel, Table as BSTable, DropdownButton, Dropdown, FormControl, Row, Card } from 'react-bootstrap';
import { FormattedMessage, FormattedDate, FormattedDateTime } from 'rapidfab/i18n';
import PropTypes from 'prop-types';

import Config from 'rapidfab/config';
import {
  CUSTOM_FIELD_TYPES,
  PRINT_TYPES,
  RELATED_TABLE_NAMES,
  ROUTES,
  SHIPMENT_DATE_TYPES,
  USER_ROLES,
} from 'rapidfab/constants';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { getLabelColor } from 'rapidfab/components/organize/ShipmentDatesColumn';

import StatusColorCode from 'rapidfab/components/StatusColorCode';
import ModelerStatusDot from 'rapidfab/components/modelerStatusDot';
import Loading from 'rapidfab/components/Loading';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _truncate from 'lodash/truncate';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import DebugModeDataPanel from 'rapidfab/components/DebugMode/DebugModeDataPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faChevronDown, faChevronUp, faLock, faSquare, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { DebugModeBadge } from 'rapidfab/components/DebugMode/DebugModeComponents';
import { MATERIAL_UNITS_MAP } from 'rapidfab/mappings';
import FormattedWeight from '../FormattedWeight';

/**
 * Conditional component - Displays enclosing grouping card UI if isGrouped is true.
 */
export const CollapsibleGroup = ({ children, isGrouped, groupData }) => {
  const { groupDisplayUuid } = groupData || {};
  const [collapsed, setCollapsed] = useState(false);
  const toggleShowBody = () => setCollapsed(previous => !previous);
  if (isGrouped) {
    return (
      <Card bg="dark">
        <Card.Header role="button" onClick={toggleShowBody} className="pd-exp inverse">
          <div className="d-flex w-100 justify-between align-items-center">
            <div className="d-flex w-100 align-items-center">
              {groupData.groupDisplayLabel}
              {' '}
              {groupDisplayUuid && `(${groupDisplayUuid})`}
            </div>
            <FontAwesomeIcon
              role="button"
              onClick={toggleShowBody}
              size="sm"
              icon={collapsed ? faChevronDown : faChevronUp}
            />
          </div>
        </Card.Header>
        {!collapsed && (
          <div className="card-body-wrapper">
            <Card.Body>
              {children}
            </Card.Body>
          </div>
        )}
      </Card>
    );
  }
  return <>{children}</>;
};

CollapsibleGroup.propTypes = {
  children: PropTypes.node.isRequired,
  isGrouped: PropTypes.bool.isRequired,
  groupData: PropTypes.shape({
    groupDisplayLabel: PropTypes.string.isRequired,
    groupDisplayUuid: PropTypes.string,
  }).isRequired,
};

const Filter = ({ globalValue, setGlobalValue }) => {
  const [value, setValue] = useState(globalValue ?? '');
  // eslint-disable-next-line no-shadow
  const onChange = useAsyncDebounce(value => setGlobalValue(value), 300);

  return (
    <Col lg={12}>
      <FormControl
        type="text"
        value={value}
        placeholder="Filter"
        onChange={({ target }) => {
          setValue(target.value);
          onChange(target.value);
        }}
      />
    </Col>
  );
};

Filter.propTypes = {
  globalValue: PropTypes.string.isRequired,
  setGlobalValue: PropTypes.func.isRequired,
};

const Table = ({
  tableID,
  data,
  columns,
  limit,
  isFetching,
  skipOffset,
  isFilteringEnabled = true,
  withDefaultPagination = true,
  initialSortedColumn,
  initialSortedDesc,
  isManualSoringEnabled,
  isUpdatedColumnShown = true,
  manualSortingFunc,
  resetDefaultSortingState = false,
  autoResetPage = true,
  isDebugModeEnabled,
  PaginationComponent,
  groupData,
  isGrouped,
  isResizable,
  isLayoutCustomizable,
}) => {
  const [sortByManually, setSortByManually] = useState(() => (
    initialSortedColumn
      ? { id: initialSortedColumn, sortAscending: !initialSortedDesc }
      : {}),
  );

  const sortByValue = useMemo(
    () => localStorage.getItem('Sorting') && JSON.parse(localStorage.getItem('Sorting'))[tableID],
    [],
  );
  const initialSortedColumnSortByValue = useMemo(() => [{ id: initialSortedColumn, desc: initialSortedDesc }], []);

  const renderColumnHeader = useMemo(() => column => (
    // TODO: Hide entire column if debug mode is enabled.
    <React.Fragment key={column.uid}>
      {column?.debugMode && <DebugModeBadge />}
      <FormattedMessage id={column.uid} defaultMessage={column.defaultMessage} />
    </React.Fragment>
  ), [columns]);

  const filtered = columns.filter(Boolean);
  const initialColumns = (isUpdatedColumnShown && !filtered.some(col => col.accessor === 'updated'))
    ? [...filtered, {
      type: 'time',
      uid: 'field.updated',
      accessor: 'updated',
      defaultMessage: 'Updated',
      isSortable: true,
    }]
    : filtered;

  const unique = initialColumns.filter((column, index, columns) =>
    columns.findIndex(col => (column?.accessor === col?.accessor)) === index,
  );

  const [currentColumns, setCurrentColumns] = useState(unique);
  // Hide initially hidden columns in the Table an Layout Toggle
  const [visibleColumns, setVisibleColumns] = useState(
    unique
      .filter(column => !column.isInitiallyHidden)
      .map(c => c.accessor),
  );

  // eslint-disable-next-line no-shadow
  const convert = columns => columns
    .filter(Boolean)
    .map(column => {
      switch (column.type) {
        case 'custom':
          return {
            Header: () => renderColumnHeader(column),
            ...column,
            disableSortBy: !column.isSortable,
          };
        case 'qr':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            Cell: ({ value }) => (
              <Link
                className="pull-right"
                to={getRouteURI(column.route, { uuid: extractUuid(value) }, {}, true)}
              >
                <BureauBarcodeIcon
                  className="spacer-right"
                />
                Containers
              </Link>
            ),
          };
        case 'text':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            Cell: ({ value }) => (
              value ? (
                column.short
                  ? getShortUUID(value)
                  : value
              ) : null
            ),
          };
        case 'integer':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            Cell: ({ value }) => (
              value || '0'
            ),
          };
        case 'bool':
          // Props: equals
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            Cell: ({ value }) => (
              column.equals ? (
                <FontAwesomeIcon icon={value === column.equals ? faCheckSquare : faSquare} />
              ) : (
                <FontAwesomeIcon icon={value ? faCheckSquare : faSquare} />
              )
            ),
          };
        case 'caps':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            Cell: ({ value }) => (
              <span style={{ textTransform: 'capitalize' }}>{value}</span>
            ),
          };
        case 'field':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            // Props: customFieldReference
            // eslint-disable-next-line no-shadow
            Cell: ({ row: { original: data } }) => {
              const { options } = column.customFieldReference;
              const customFieldValueObject = _find(
                data.custom_field_values, ['custom_field', column.customFieldReference.uri],
              );

              let customFieldValueString = '';

              if (customFieldValueObject) {
                customFieldValueString = customFieldValueObject.value;

                if (column.customFieldReference.type === CUSTOM_FIELD_TYPES.ARRAY) {
                  customFieldValueString = _find(options, ['value', customFieldValueString]).key;
                }
              }

              return (
                <div>{customFieldValueString}</div>
              );
            },
          };
        case 'date':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            Cell: ({ value }) => (
              value
                ? (
                  <FormattedDate
                    value={value}
                  />
                ) : (
                  <FormattedMessage
                    id="notAvailable"
                    defaultMessage="N/A"
                  />
                )
            ),
          };
        case 'time':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            Cell: ({ value }) => (
              value
                ? (
                  <FormattedDateTime
                    value={value}
                  />
                )
                : (
                  <FormattedMessage
                    id="notAvailable"
                    defaultMessage="N/A"
                  />
                )
            ),
          };
        case 'link':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            Cell: ({ value }) => (
              <span>
                <a target="_blank" href={value} rel="noreferrer">
                  {value}
                </a>
              </span>
            ),
          };
        case 'cost':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            // eslint-disable-next-line no-shadow
            Cell: ({ row: { original: data } }) => (
              data.cost
                ? (
                  <span>{`${data.cost} (${data.currency})`}</span>
                )
                : (
                  <FormattedMessage
                    id="notAvailable"
                    defaultMessage="N/A"
                  />
                )
            ),
          };
        case 'uuid':
          return {
            ...column,
            disableSortBy: true,
            Header: () => renderColumnHeader(column),
            // Props: route, resource, params
            // eslint-disable-next-line no-shadow
            Cell: ({ row: { original: data } }) => (
              <Link
                to={
                  column.route
                    ? getRouteURI(column.route, { uuid: data.uuid }, column.params, true)
                    : `/records/${column.resource}/${extractUuid(data.uri)}`
                }
              >
                {data.customer_id || getShortUUID(data.uri)}
                {column.resource === 'print' && data.type === PRINT_TYPES.SPECIMEN && ' (specimen)'}
              </Link>
            ),
          };
        case 'traveler':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            // eslint-disable-next-line no-shadow
            Cell: ({ row: { original: data } }) => (
              <a
                href={`${Config.HOST.QR}/traveler/print/${data.uuid}`}
                target="_blank"
                rel="noopener noreferrer"
                type="download"
              >
                <BureauBarcodeIcon />
              </a>
            ),
          };
        case 'modeler':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            // Props: modelers
            Cell: ({ value }) => (
              column.modelers[value]
                ? (
                  <ModelerStatusDot modeler={column.modelers[value]} />
                ) : (
                  <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                )
            ),
          };
        case 'resource':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            // eslint-disable-next-line no-shadow
            Cell: ({ row: { original: data } }) => (
              column.resources[data[column.resource]]
                ? (
                  <Link
                    to={
                      `/records/${column.slug || column.resource}/${extractUuid(column.resources[data[column.resource]].uri)}`
                    }
                  >
                    {column.resources[data[column.resource]].name}
                  </Link>
                )
                : (
                  <FormattedMessage
                    id="notAvailable"
                    defaultMessage="N/A"
                  />
                )
            ),
          };
        case 'location':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            Cell: ({ row: { original: data } }) => (
              column.resources[data[column.resource]]
                ? (
                  <Link
                    to={getRouteURI(
                      ROUTES.LOCATIONS,
                      null,
                      { uuid: extractUuid(column.resources[data[column.resource]].uri) },
                      true)}
                  >
                    {column.resources[data[column.resource]].name}
                  </Link>
                )
                : (
                  <FormattedMessage
                    id="notAvailable"
                    defaultMessage="N/A"
                  />
                )
            ),
          };
        case 'sub_location':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            Cell: ({ row: { original: data } }) => (
              column.resources[data[column.resource]]
                ? (
                  <Link
                    to={getRouteURI(
                      ROUTES.LOCATIONS,
                      null,
                      { uuid: extractUuid(column.resources[data[column.resource]].location) },
                      true)}
                    state={{ uri: column.resources[data[column.resource]].uri }}
                  >
                    {column.resources[data[column.resource]].name}
                  </Link>
                )
                : (
                  <FormattedMessage
                    id="notAvailable"
                    defaultMessage="N/A"
                  />
                )
            ),
          };
        case 'suffixed':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            // Props: suffix
            // eslint-disable-next-line no-shadow
            Cell: ({ value, row: { original: data } }) => (
              column.suffix && data[column.suffix] && (
                <FormattedWeight value={value} valueUnits={data[column.suffix]} unitMapping={MATERIAL_UNITS_MAP} />
              )
            ),
          };
        case 'translatable':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            Cell: ({ value }) => (
              column.mapping[value]
                ? (
                  <>
                    {column.coloured &&
                      <StatusColorCode status={value} type={column.colorScheme} />} <span className="order-status-dot" />
                    <FormattedMessage
                      id={column.mapping[value].id}
                      defaultMessage={column.mapping[value].defaultMessage}
                    />
                  </>
                )
                : (
                  <FormattedMessage
                    id="notAvailable"
                    defaultMessage="N/A"
                  />
                )
            ),
          };
        case 'record':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            // Props: resource, uri
            // eslint-disable-next-line no-shadow
            Cell: ({ value, row: { original: data } }) => (
              <span>
                <Link to={
                  column.route ?
                    getRouteURI(column.route, { uuid: data.uuid }, column.params, true)
                    : `/records/${column.resource}/${extractUuid(data[column.uri])}`
                }
                >
                  {value}
                  {
                    data.locked &&
                    (data.prints_fill !== null && data.prints_fill < 100 ? (
                      <span>
                        {' ('}
                        <FontAwesomeIcon icon={faUnlock} />
                        {` ${data.prints_fill}%)`}
                      </span>
                    )
                      : (
                        <span>
                          {' '}
                          <FontAwesomeIcon icon={faLock} />
                        </span>
                      )
                    )
                  }
                </Link>
              </span>
            ),
          };
        case 'modal':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            // Props: func
            // eslint-disable-next-line no-shadow
            Cell: ({ row: { original: data } }) => (
              <Button
                className="p-a-0"
                variant="link"
                role="button"
                tabIndex={0}
                onClick={() => column.func(data.uuid)}
              >
                {getShortUUID(data.uuid)}
              </Button>
            ),
          };
        case 'service':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            Cell: ({ row: { original: { serviceProviders } } }) => (
              <div>
                {_truncate(_map(serviceProviders, 'name').join(', '))}
              </div>
            ),
          };
        case 'contact':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            Cell: ({ value }) => (
              <span>
                {column.users[value] ? (
                  column.users[value].name || column.users[value].username
                ) : (
                  <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                )}
              </span>
            ),
          };
        case 'shipment':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            // Props: shipment
            // eslint-disable-next-line no-shadow
            Cell: ({ row: { original: data } }) => {
              const {
                actual_shipment_date,
                actual_delivery_date,
                estimated_shipment_date,
                estimated_delivery_date,
              } = data;

              // set actual and estimate to be shipment dates
              let actual = actual_shipment_date;
              let estimated = estimated_shipment_date;
              // if type is delivery, set actual and estimate to be delivery dates
              if (column.shipment === SHIPMENT_DATE_TYPES.DELIVERY) {
                actual = actual_delivery_date;
                estimated = estimated_delivery_date;
              }

              // if neither field is available, return N/A
              if (!actual && !estimated) {
                return <FormattedMessage id="notAvailable" defaultMessage="N/A" />;
              }
              // if only estimated date return MM/DD/YYYY / N/A
              if (!actual && estimated) {
                return (
                  <div>
                    <FormattedDate value={estimated} /> / <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                  </div>
                );
              }
              // if only actual date return N/A / MM/DD/YYYY (estimated field is empty when
              // run scheduling process is not completed)
              if (actual && !estimated) {
                return (
                  <div>
                    <FormattedMessage id="notAvailable" defaultMessage="N/A" /> / <FormattedDate value={actual} />
                  </div>
                );
              }
              // return dates with color coding
              return (
                <div>
                  <FormLabel variant={getLabelColor(estimated, actual)} style={{ fontSize: '14px' }}>
                    <FormattedDate value={estimated} /> / <FormattedDate value={actual} />
                  </FormLabel>
                </div>
              );
            },
          };
        case 'color':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            Cell: ({ value }) => (
              <div
                style={{ margin: '0 auto', width: 24, height: 24, backgroundColor: value }}
              />
            ),
          };
        case 'workflow':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            // eslint-disable-next-line no-shadow
            Cell: ({ value }) => (
              value === undefined ?
                '' :
                <FormattedMessage id={`workflowType.${value}`} />
            ),
          };
        case 'title':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            // Props: role
            // eslint-disable-next-line no-shadow
            Cell: ({ row: { original: data } }) => {
              let route = null;

              const availableRoutes = Object.values(ROUTES);
              const fallbackRouteTemplate = `/records/${data.related_table_name}/:uuid`;
              const fallbackRoute = availableRoutes.includes(fallbackRouteTemplate)
                // As A fall-back - use table name as resource only if route exists
                ? `/records/${data.related_table_name}/${data.related_uuid}`
                : null;

              const getOrderRoute = () => (
                column.role === USER_ROLES.RESTRICTED
                  ? getRouteURI(ROUTES.ORDER_RESTRICTED_EDIT, { uuid: data.related_uuid }, {}, true)
                  : getRouteURI(ROUTES.ORDER_EDIT, { uuid: data.related_uuid }, {}, true)
              );

              switch (data.related_table_name) {
                case RELATED_TABLE_NAMES.ORDER:
                  route = getOrderRoute();
                  break;
                case RELATED_TABLE_NAMES.PREP_TASK_RECORD:
                  route = getRouteURI(ROUTES.PREP_TASK_RECORDS, {}, { task: data.related_uuid }, {}, true);
                  break;
                case RELATED_TABLE_NAMES.COMMENT_ACTION:
                  route = getRouteURI(ROUTES.COMMENT_ACTION, { uuid: data.related_uuid }, {}, true);
                  break;
                default:
                  route = fallbackRoute;
                  break;
              }
              return <a href={route}>{data.name}</a>;
            },
          };
        case 'age':
          return {
            ...column,
            disableSortBy: !column.isSortable,
            Header: () => renderColumnHeader(column),
            Cell: ({ value }) => (
              value ? (
                `~ ${dayjs(value)
                  .fromNow(true)}`
              ) : (
                <FormattedMessage id="notAvailable" defaultMessage="N/A" />
              )
            ),
          };
        default:
          return column;
      }
    });

  const handleToggleColumn = accessor => {
    if (!isLayoutCustomizable) return;
    setVisibleColumns(previous => (previous.includes(accessor)
      ? previous.filter(col => col !== accessor)
      : [...previous, accessor]),
    );
  };

  const handleReorderColumns = useCallback(newOrder => {
    if (!isLayoutCustomizable) return;
    setCurrentColumns(newOrder);
  }, [isLayoutCustomizable]);

  const memoizedData = useMemo(() => [...data], [data]);
  // Decide which columns to convert based on isLayoutCustomizable

  const convertedColumns = useMemo(() => convert(unique), [columns]);

  const memoizedColumns = useMemo(() => {
    const columnMap = new Map(convertedColumns.map(col => [col.accessor, col]));

    return currentColumns
      .map(({ accessor }) => columnMap.get(accessor))
      .filter(col => col && visibleColumns.includes(col.accessor));
  }, [convertedColumns, currentColumns, visibleColumns]);

  const defaultColumn = React.useMemo(
    () => (isResizable ? ({
      minWidth: 50, // Minimum width of a column
      width: 100, // Initial width of a column
      maxWidth: 400, // Maximum width of a column when resized
    }) : {}),
    [isResizable],
  );

  const tableHooks = [
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    ...(isResizable ? [useFlexLayout, useResizeColumns] : []),
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    state:
      {
        pageIndex,
        sortBy,
      },
    pageCount,
    gotoPage,
    setPageSize,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    globalFilter,
    setGlobalFilter,
  } = useTable({
    // To prevent resetting the page when the data changes and re-renders
    autoResetPage,
    columns: memoizedColumns,
    data: memoizedData,
    defaultColumn,
    initialState: JSON.parse(localStorage.getItem('Sorting')) && JSON.parse(localStorage.getItem('Sorting'))[tableID] && !resetDefaultSortingState ? {
      sortBy: sortByValue,
    } : (initialSortedColumn ? {
      sortBy: initialSortedColumnSortByValue,
    } : {}),
    keepPinnedRows: true,
    manualSortBy: isManualSoringEnabled,
  }, ...tableHooks);

  const isResizingRef = useRef(false);
  const wasResizingRef = useRef(false);

  const isResizing = isResizable && headerGroups.some(headerGroup =>
    headerGroup.headers.some(column => column.isResizing),
  );

  useEffect(() => {
    // Skip resizing logic if not resizable
    if (!isResizable) return;

    if (isResizingRef.current && !isResizing) {
      // Resizing just ended
      wasResizingRef.current = true;
    }
    isResizingRef.current = isResizing;
  }, [isResizable, isResizing]);

  useEffect(() => (limit ? setPageSize(limit) : setPageSize(25)), []);

  useEffect(() => {
    if (!isManualSoringEnabled && tableID) {
      const previous = JSON.parse(localStorage.getItem('Sorting'));
      localStorage.setItem('Sorting', JSON.stringify(previous
        ? { ...previous, [tableID]: sortBy }
        : { [tableID]: sortBy }));
      return;
    }
    const refreshed = {
      id: sortBy[0]?.id,
      sortAscending: !sortBy[0]?.desc,
    };
    if (refreshed) {
      setSortByManually(refreshed);
    }
  }, [sortBy]);

  useEffect(() => {
    if (isManualSoringEnabled && sortByManually.id) {
      manualSortingFunc(sortByManually);
    }
  }, [sortByManually]);

  const pages = Array
    .from({ length: pageCount }, (_, index) => pageCount - index)
    .reverse();

  return (
    isFetching
      ? (
        <Loading />
      )
      : (data.length === 0
        ? <h1 className="text-center mt-2">Nothing found</h1>
        : (
          <Row className="d-flex justify-content-center">
            <Col xs={12} lg={skipOffset ? 12 : 10} className={isLayoutCustomizable && 'position-relative'}>
              {isLayoutCustomizable && (
                <ColumnVisibilityMenu
                  columns={currentColumns}
                  visibleColumns={visibleColumns}
                  onToggleColumn={handleToggleColumn}
                  onReorderColumns={handleReorderColumns}
                />
              )}
              <Row className="d-flex justify-content-between mb15">
                <Col xs={12} lg={4}>

                  {isFilteringEnabled && <Filter globalValue={globalFilter} setGlobalValue={setGlobalFilter} />}
                </Col>
                <Col xs={12} lg={8} className="d-flex justify-content-end">

                  {withDefaultPagination && (
                    <div>
                      <ul
                        style={{
                          maxWidth: '280px',
                          display: 'flex',
                          marginLeft: 'auto',
                        }}
                      >
                        <Button
                          style={{ marginRight: '5px' }}
                          disabled={!canPreviousPage}
                          onClick={() => previousPage()}
                        >
                          Previous
                        </Button>
                        <Button
                          disabled={!canNextPage}
                          onClick={() => nextPage()}
                        >
                          Next
                        </Button>
                        <DropdownButton
                          title={`Page: ${pageIndex + 1}`}
                          style={{ marginLeft: '5px' }}
                          onSelect={eventKey => gotoPage(Number(eventKey) - 1)}
                        >
                          {pages.map(count => (<Dropdown.Item key={count} eventKey={count}>{count}</Dropdown.Item>))}
                        </DropdownButton>
                      </ul>
                    </div>
                  )}
                </Col>
              </Row>
              {/* Collapsible group UI will not enclose rows if `isGrouped` prop is false. */}
              <CollapsibleGroup isGrouped={isGrouped} groupData={groupData}>
                <div className="table-container">
                  <BSTable bordered hover {...getTableProps()}>
                    <thead>
                      {headerGroups.map(headerGroup => (
                        <tr
                          {...headerGroup.getHeaderGroupProps()}
                          style={isResizable ? { display: 'flex' } : {}}
                        >
                          {headerGroup.headers.map(column => {
                            // Prepare header props
                            const defaultHeaderProps = column.getHeaderProps();
                            const sortByToggleProps = column.getSortByToggleProps();

                            // Override the onClick handler
                            const onClick = event => {
                              if (isResizable && wasResizingRef.current) {
                                event.preventDefault();
                                event.stopPropagation();
                                // Reset the flag
                                wasResizingRef.current = false;
                                return;
                              }
                              if (sortByToggleProps.onClick) {
                                sortByToggleProps.onClick(event);
                              }
                            };

                            const headerProps = {
                              ...defaultHeaderProps,
                              ...(isResizable ? { onClick } : sortByToggleProps),
                            };

                            return (
                              <th
                                {...headerProps}
                                style={{
                                  ...column.getHeaderProps(column.getSortByToggleProps()).style,
                                }}
                              >
                                {column.render('Header')}
                                {column.canResize && isResizable && (
                                  <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                                  />
                                )}
                                {column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}
                              </th>
                            );
                          })}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {(withDefaultPagination ? page : rows).map(row => {
                        prepareRow(row);
                        return (
                          <React.Fragment key={row.getRowProps().key}>
                            <tr {...row.getRowProps()} style={isResizable ? { display: 'flex' } : {}}>
                              {row.cells.map(cell => (
                                <td
                                  {...cell.getCellProps()}
                                  style={{
                                    ...cell.getCellProps().style,
                                    ...(isResizable ? { maxWidth: 'unset' } : {}),
                                  }}
                                >
                                  {cell.render('Cell')}
                                </td>
                              ))}
                            </tr>
                            {isDebugModeEnabled && (
                              <tr>
                                <td colSpan={columns.length}>
                                  <DebugModeDataPanel data={row.original} />
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </BSTable>
                </div>

              </CollapsibleGroup>
              {PaginationComponent}
            </Col>
          </Row>
        ))
  );
};

/* eslint-disable react/require-default-props */

Table.propTypes = {
  tableID: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    accessor: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    uri: PropTypes.string,
    defaultMessage: PropTypes.string,
    route: PropTypes.string,
    short: PropTypes.bool,
    slug: PropTypes.string,
    role: PropTypes.string,
    func: PropTypes.func,
    equals: PropTypes.string,
    users: PropTypes.shape({}),
    params: PropTypes.shape({}),
    resource: PropTypes.string,
    resources: PropTypes.objectOf(PropTypes.shape({
      uri: PropTypes.string,
      name: PropTypes.string,
    })),
    customFieldReference: PropTypes.string,
    modelers: PropTypes.objectOf(PropTypes.shape({})),
    shipment: PropTypes.string,
    suffix: PropTypes.string,
    mapping: PropTypes.objectOf(PropTypes.shape({})),
    coloured: PropTypes.bool,
    colorScheme: PropTypes.string,
  })).isRequired,
  limit: PropTypes.number,
  isFetching: PropTypes.bool,
  isFilteringEnabled: PropTypes.bool,
  isUpdatedColumnShown: PropTypes.bool,
  withDefaultPagination: PropTypes.bool,
  initialSortedColumn: PropTypes.string,
  initialSortedDesc: PropTypes.bool,
  isManualSoringEnabled: PropTypes.bool,
  manualSortingFunc: PropTypes.func,
  value: PropTypes.string,
  setValue: PropTypes.func,
  row: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    original: PropTypes.shape({
      serviceProviders: PropTypes.arrayOf(PropTypes.shape({})),
      name: PropTypes.string,
      custom_field_values: PropTypes.arrayOf(PropTypes.shape({})),
      cost: PropTypes.number,
      description: PropTypes.string,
      id: PropTypes.string,
      uuid: PropTypes.string,
      currency: PropTypes.string,
      locked: PropTypes.bool,
      type: PropTypes.string,
      uri: PropTypes.string,
      prints_filled: PropTypes.number,
      actual_delivery_date: PropTypes.string,
      estimated_shipment_date: PropTypes.string,
      estimated_delivery_date: PropTypes.string,
      actual_shipment_date: PropTypes.string,
      actual_delivery_date_time: PropTypes.string,
      customer_id: PropTypes.string,
      prints_fill: PropTypes.number,
      related_table_name: PropTypes.string,
      related_uuid: PropTypes.string,
    }),
  }),
  resetDefaultSortingState: PropTypes.bool,
  autoResetPage: PropTypes.bool,
  isDebugModeEnabled: PropTypes.bool.isRequired,
  PaginationComponent: PropTypes.element,
  groupData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({
      groupDisplayLabel: PropTypes.string.isRequired,
      groupDisplayUuid: PropTypes.string,
    }),
  ]).isRequired,
  isGrouped: PropTypes.bool,
  skipOffset: PropTypes.bool,
  isResizable: PropTypes.bool,
  isLayoutCustomizable: PropTypes.bool,
};

export default Table;
