import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import Actions from 'rapidfab/actions';
import MaterialLotNewComponent from 'rapidfab/components/records/MaterialLotNew';
import { getMaterials, getBureauUri, getSubLocations, getLocationsByRole } from 'rapidfab/selectors';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { ROUTES, MATERIAL_LOT_STATUSES, API_RESOURCES } from 'rapidfab/constants';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _set from 'lodash/set';
import getRouteParameters from 'rapidfab/utils/getRouteParameters';
import { extractUuid } from 'rapidfab/utils/uuidUtils';

import { MATERIAL_LOT_NEW_CONTAINER } from 'rapidfab/constants/forms';
import Alert from 'rapidfab/utils/alert';
import { FormattedMessage } from 'react-intl';

// eslint-disable-next-line no-return-assign
// 👇🏼 Used?
// const redirect = () => window.location.hash = getRouteURI(ROUTES.MATERIAL_LOTS);

const redirectToNewMaterialLot = uri => {
  window.location.hash = getRouteURI(ROUTES.MATERIAL_LOT, { uuid: extractUuid(uri) });
};

const MaterialLotNewContainer = props => {
  const bureau = useSelector(getBureauUri);
  const { isOrderMaterialLot } = getRouteParameters();
  const initialFormValues = {
    number_of_containers: 1,
  };
  const isSubmitting = useSelector(state => state.ui.nautilus[API_RESOURCES.MATERIAL_LOT].post.fetching);
  const materials = useSelector(getMaterials);
  const locations = useSelector(getLocationsByRole);
  const subLocations = useSelector(getSubLocations);

  if (!isOrderMaterialLot) {
    initialFormValues.actual_delivery_date = dayjs().format('YYYY-MM-DD');
  }
  const selected = {
    bureau,
    isSubmitting,
    materials,
    locations,
    isOrderMaterialLot,
    initialFormValues,
    subLocations,
  };

  const dispatch = useDispatch();

  const onInitialize = currentBureau => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: currentBureau }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
  };

  const loadSubLocations = async locationUri => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].clear('list'));
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list({ location: locationUri }));
  };

  const onSave = payload => {
    const submitData = { ...payload };

    MATERIAL_LOT_NEW_CONTAINER.NULL_FIELDS.forEach(fieldName => {
      if (!submitData[fieldName]) delete submitData[fieldName];
    });

    // TODO: Convert string via some component instead to prevent code duplication
    // https://app.clubhouse.io/authentise/story/11365/design-for-timezones-for-date-field-in-nautilus-and-rapidfab
    MATERIAL_LOT_NEW_CONTAINER.DATE_FIELDS.forEach(
      fieldName => {
        // Convert dates to ISO string in order to include timezone
        if (submitData[fieldName]) {
          const date = new Date(`${submitData[fieldName]}T00:00`);
          submitData[fieldName] = date.toISOString();
        }
      },
    );

    MATERIAL_LOT_NEW_CONTAINER.FLOAT_FIELDS.forEach(
      fieldName => {
        const field = _get(submitData, fieldName);
        if (field) {
          _set(submitData, fieldName, Number.parseFloat(field));
        }
      },
    );

    MATERIAL_LOT_NEW_CONTAINER.INTEGER_FIELDS.forEach(
      fieldName => {
        const field = _get(submitData, fieldName);
        if (field) {
          _set(submitData, fieldName, Number(field));
        }
      },
    );

    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].post(submitData)).then(response => {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT]
        .list({ uri: response.headers.location }))
        .then(data => {
          // uri passed to the "list" filter above
          const uri = data?.filters?.uri;
          if (!uri) {
            return Alert.error(
              <FormattedMessage
                id="toaster.materialLot.error"
                defaultMessage="Something went wrong while creating Material Lot. Please try again."
              />,
            );
          }
          Alert.success(
            <FormattedMessage
              id="toaster.materialLot.created"
              defaultMessage="Successfully created Material Lot."
            />,
          );
          return redirectToNewMaterialLot(uri);
        });
    });
  };

  useEffect(() => onInitialize(bureau), []);

  const onSubmit = formData => {
    const payload = formData;
    if (isOrderMaterialLot) {
      payload.status = MATERIAL_LOT_STATUSES.ON_ORDER;
    }
    return onSave(payload);
  };

  return (
    <MaterialLotNewComponent
      {...props}
      {...selected}
      onSubmit={onSubmit}
      loadSubLocations={loadSubLocations}
    />
  );
};

MaterialLotNewContainer.propTypes = {
  queryParams: PropTypes.shape({
    isOrderMaterialLot: PropTypes.string,
  }).isRequired,
};

export default MaterialLotNewContainer;
