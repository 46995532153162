import React from 'react';
import { Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';
import _capitalize from 'lodash/capitalize';

const AddiguruAnalysisStatus = ({ status }) => {
  if (status === 'ok') {
    return <Badge bg="success">OK</Badge>;
  }
  if (status === 'paused') {
    return <Badge bg="warning">Paused</Badge>;
  }
  if (status === 'failed') {
    return <Badge bg="danger">Failed</Badge>;
  }
  if (status === 'defect') {
    return <Badge bg="danger">Defect</Badge>;
  }
  // Otherwise, render as capitalized, space separated string
  return <Badge bg="secondary">{status.split('_').map(word => _capitalize(word)).join(' ')}</Badge>;
};

AddiguruAnalysisStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default AddiguruAnalysisStatus;
